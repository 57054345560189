import React from 'react'

function Footer() {
  return (
    <div>
            <footer className ="footer">
                        <div className ="container">
                            <div className ="row align-items-center">
                                <div className ="col-md-12 col-sm-12 text-center">
                                    Copyright © 2023 <a href="">MindForce</a>. All rights reserved
                                </div>
                                <div className ="col-md-12 col-sm-12 text-center">
                                    <span className ="fs-10 font-weight-bold text-info">v1.0.1</span>
                                </div>
                            </div>
                        </div>
                    </footer>
    </div>
  )
}

export default Footer