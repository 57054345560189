import React from "react"

function Preloader() {
    return (
        <div className={"row"} style={{width:"100%"}}>
            <div className={"item"} style={{textAlign:"center", width:"100%"}}>
                <img src="https://i.pinimg.com/originals/78/e8/26/78e826ca1b9351214dfdd5e47f7e2024.gif" style={{maxHeight:"400px"}} />
                {/* <Loader type="spinner-circle" bgColor={"#000"} title={"spinner-circle"} color={'#FFFFFF'} size={100} /> */}
            </div>
        </div>
    )

}
export default Preloader;