import React, { useEffect, useState } from "react";
import { postAPI } from "../ApiService"; 
import { useNavigate, useLocation } from "react-router-dom";
const defaultTime = 60;

function OtpVerify(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const initialValues = { password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [timeLeft, setTimeLeft] = useState(defaultTime);
    const [minLeft, setMinLeft] = useState();
    const [secleft, setSecleft] = useState();
    const [digit1, setDigit1] = useState();
    const [digit2, setDigit2] = useState();
    const [digit3, setDigit3] = useState();
    const [digit4, setDigit4] = useState();
    const [digit5, setDigit5] = useState();
    const [digit6, setDigit6] = useState();
    const [otpError, setOtpError] = useState();
    const [accessToken, setAccessToken] = useState(props?.formValues.accessToken);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    useEffect(() => {
        let interval = setInterval(() => {
            if(timeLeft === 0){
                setTimeLeft(0)
            }
            setTimeLeft((t) => t - 1);
        }, 1000);
        setMinLeft(parseInt(timeLeft/60));
        setSecleft(timeLeft%60)
        if(timeLeft === 0){
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timeLeft]);

    const resendOtp = () => {
        setOtpError()
        sendOtpOnMail()
        setTimeLeft(30);
        document.getElementById('otpchar1').value=""
        document.getElementById('otpchar1').focus()
        document.getElementById('otpchar2').value=""
        document.getElementById('otpchar3').value=""
        document.getElementById('otpchar4').value=""
        document.getElementById('otpchar5').value=""
        document.getElementById('otpchar6').value=""
    }

    const sendOtpOnMail = async () => {
        let data = {
            email:props.formValues.email,
            source:"web",
            _token:props.formValues._token
        }
        let res = await postAPI('/user/forgot-password/send-otp', data)
        if(res.status === 1){
            setAccessToken(res.payload.accessToken);
            //Show otp sent message
            localStorage.setItem("token",res.payload.token)
        } else{
            setOtpError(res.message)
        }
    }

    const handleOTP = (e) => {
        let id = e.target.id;
        let value = e.target.value
        switch (id) {
            case 'otpchar1':
                if(value.length === 1){
                    setDigit1(value);
                    document.getElementById('otpchar2').focus()
                }else{
                    setDigit1();
                    document.getElementById('otpchar1').focus()
                }
                break;
            case 'otpchar2':
                if(value.length === 1){
                    setDigit2(value);
                    document.getElementById('otpchar3').focus()
                }else{
                    setDigit2();
                    document.getElementById('otpchar1').focus()
                }
                
                break;
            case 'otpchar3':
                if(value.length === 1){
                    setDigit3(value);
                    document.getElementById('otpchar4').focus()
                }else{
                    setDigit3();
                    document.getElementById('otpchar2').focus()
                }
                
                break;
            case 'otpchar4':
                if(value.length === 1){
                    setDigit4(value);
                    document.getElementById('otpchar5').focus()
                }else{
                    setDigit4();
                    document.getElementById('otpchar3').focus()
                }
            break;
            case 'otpchar5':
                if(value.length === 1){
                    setDigit5(value);
                    document.getElementById('otpchar6').focus()
                }else{
                    setDigit5();
                    document.getElementById('otpchar4').focus()
                }
                break;
            case 'otpchar6':
                if(value.length === 1){
                    setDigit6(value);
                }else{
                    setDigit6();
                    document.getElementById('otpchar5').focus()
                }
                break;
        
            default:
                break;
        }
    }

    const verifyOTP = async () => {
        setOtpError()
        if(!digit1 || !digit2 || !digit3 || !digit4 || !digit5 || !digit6){
            return setOtpError("Invalid OTP")
        }
        let otp = digit1+digit2+digit3+digit4+digit5+digit6;
        let payloadData = {
            otp: otp,
            email: props.formValues.email,
            accessToken: accessToken,
            source: "web",
            password: formValues.password,
            _token:props.formValues._token
        }
        let res = await postAPI('/user/forgot-password/verify-otp',payloadData)
        if(res.status === 1){
            localStorage.setItem("token",res.payload.token);
            navigate('/')
        } else{
            //Show error message
            setOtpError(res.message)
        }
    }

    const returnBack = () => {
        props.backToInfo()
    }

    return (<>
        <div className="card-body  pb-4 pt-0 sign">
            <div className="divider">
                <div className="divider-text text-muted">
                    <large onClick={returnBack} style={{cursor:"pointer", marginRight:"10px"}}> <i className="fa fa-arrow-circle-left"></i> </large>
                    <small style={{fontSize:"100%"}}>  Verify OTP &amp; Reset password </small>
                </div>
            </div>

            <span className="mb-2" style={{color:"#ff0000"}}>{otpError}</span>
            <div className="row">
                <div className="col-lg-12">
                    <label for="otp" className="fs-12 font-weight-bold text-md-right  mb-2">OTP<span className="required_f">*</span></label>
                    <div className="otp input-box mb-5 ">
                        <input className="prompt srch_explore otpBox" onChange={(e) => handleOTP(e)} type="text" id="otpchar1" required="" maxLength="1" />
                        <input className="prompt srch_explore otpBox" onChange={(e) => handleOTP(e)} type="text" id="otpchar2" required="" maxLength="1" />
                        <input className="prompt srch_explore otpBox" onChange={(e) => handleOTP(e)} type="text" id="otpchar3" required="" maxLength="1" />
                        <input className="prompt srch_explore otpBox" onChange={(e) => handleOTP(e)} type="text" id="otpchar4" required="" maxLength="1" />
                        <input className="prompt srch_explore otpBox" onChange={(e) => handleOTP(e)} type="text" id="otpchar5" required="" maxLength="1" />
                        <input className="prompt srch_explore otpBox" onChange={(e) => handleOTP(e)} type="text" id="otpchar6" required="" maxLength="1" />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="input-box mb-5">
                        <label for="password" className="fs-12 font-weight-bold text-md-right  mb-2">Password<span className="required_f">*</span></label>
                        <input id="password" type="password" className="form-control" name="password" autocomplete="off" placeholder="Please enter new password" value={formValues.password} onChange={handleChange} required />
                    </div>
                </div>
            </div>

            <div className="input-box mb-10">
                {timeLeft > 0 ? 
                    <span className="custom-switch-description" style={{float:"right", marginBottom:"20px"}}><span style={{fontWeight:"bold", color:"#ccc"}}>Resend in </span>{minLeft}:{secleft < 10 ? "0" + secleft : secleft} Sec</span> : 
                    <span className="custom-switch-description" style={{float:"right", marginBottom:"20px"}}><span>Didn't receive the OTP?</span> <span style={{cursor:"pointer", color:"#ff0000"}} onClick={() => resendOtp()}> Resend OTP</span></span>
                }
            </div>

            <div className="form-group mb-0">
                <button onClick={() => verifyOTP()} className="btn btn-primary mr-2">Verify</button>
            </div>

        </div>
    </>)
}

export default OtpVerify