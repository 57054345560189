import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import Data from '../../Form_Data';
import { CheckPicker } from 'rsuite';
import Swal from "sweetalert2";
import { checkExpiry } from '../../Common/ExpiryTimeCheck';
import DonutChart from "react-donut-chart";
import Preloader from '../Common/Preloader';
import { getPanelBookB2c } from '../../Api/PanelBook';
import { changeNumberFormat } from '../../Common/NumberConversion';
import { Chart } from "react-google-charts";
import { getB2cSampleSize, saveB2cData } from '../../Api/SurveySampling';
import { masterData } from '../../Api/Master';
import { format } from 'date-fns';

function Survey_sampling_b2c() {
    useEffect(() => {
        document.title = "Survey Sampling B2C - Mindforce"
    },);

    const [levelOfSurvey, setLevelOfSurvey] = useState()
    const [surveyDuration, setSurveyDuration] = useState()
    const [surveyStartFrom, setSurveyStartFrom] = useState()
    const [surveyEndFrom, setSurveyEndFrom] = useState()
    const [ir, setIR] = useState()
    const [surveySamplingMethod, setSurveySamplingMethod] = useState([])

    const [levelOfSurveys, setLevelOfSurveys] = useState()
    const [surveyDurations, setSurveyDurations] = useState()
    const [irs, setIRs] = useState()
    const [surveySamplingMethods, setSurveySamplingMethods] = useState()

    // error msg
    const [levelOfSurveYError, setLevelOfSurveYError] = useState()
    const [surveyDurationError, setSurveyDurationError] = useState()
    const [surveyStartError, setSurveyStartError] = useState()
    const [irError, setIrError] = useState()
    const [surveyEndError, setSurveyEndError] = useState()
    const [surveyMethodError, setSurveyMethodError] = useState()

    const token = localStorage.getItem('token')
    // fields
    const [regions, setRegions] = useState();
    const [countries, setCountries] = useState([]);
    const [genders, setGenders] = useState();
    const [ages, setAges] = useState();
    const [occupations, setOccupations] = useState()
    const [incomes, setIncomes] = useState();
    const [selectedRegion, setselectedRegion] = useState([])
    const [selectedRegionDetail, setSelectedRegionDetail] = useState([]);
    const [country, setCountry] = useState([])
    const [Gender, setGender] = useState([])
    const [Age, setAge] = useState([])
    const [occupation, setOccupation] = useState([])
    const [income, setIncome] = useState([]);
    const [noData, setNoData] = useState()
    const [totalSampleSize, setTotalSampleSize] = useState();
    let reactDonutChartStrokeColor = "#FFFFFF";
    
    useEffect(() => {
        console.log("start")
        getMasterData()
    },[])

    const getMasterData = async () =>{
        await getRegions()
        // await getCountries()
        await getGender()
        await getAges()
        await getOccupations()
        await getIncome()
        await getSurveyLevel()
        await getSurveyDuration()
        await getIrs()
        await getSurveySamplingMethod()
        // getDepartment()
    }

    const getSurveyLevel = async () => {
        let data = await masterData({ moduleName: 'survey-level', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setLevelOfSurveys(data.payload);
        }
    }
    const getSurveyDuration = async () => {
        let data = await masterData({ moduleName: 'survey-duration', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setSurveyDurations(data.payload);
        }
    }
    const getIrs = async () => {
        let data = await masterData({ moduleName: 'estimated-ir-b2c', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setIRs(data.payload);
        }
    }
    const getSurveySamplingMethod = async () => {
        let data = await masterData({ moduleName: 'sampling-method-b2c', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setSurveySamplingMethods(data.payload);
        }
    }

    const getRegions = async () => {
        let data = await masterData({ moduleName: 'region-b2c', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setRegions(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            getCountries();
        }
    }, [selectedRegion])

    const getCountries = async () => {
        let countryArray = [];
        for (let i = 0; i < selectedRegion.length; i++) {
            let data = await masterData({ moduleName: 'country-b2c', pagePerRec: 250, page: 1, parentId:selectedRegion[i] })
            if (data.status === 1) {
                for (let j = 0; j < data.payload.length; j++) {
                    countryArray.push({ label: data.payload[j].name, value: data.payload[j].id, parentRegion:selectedRegionDetail[i].name, parentId:selectedRegion[i] })
                    // setCountries(data.payload);
                }
            }
        }
        setCountries(countryArray);
    }

    const getGender = async () => {
        let data = await masterData({ moduleName: 'gender', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setGenders(data.payload);
        }
    }

    const getAges = async () => {
        let data = await masterData({ moduleName: 'age-group-b2c', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setAges(data.payload);
        }
    }

    const getOccupations = async () => {
        let data = await masterData({ moduleName: 'occupation', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setOccupations(data.payload);
        }
    }

    const getIncome = async () => {
        let data = await masterData({ moduleName: 'income-group', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setIncomes(data.payload);
        }
    }

    useEffect(() => {
        getB2cData();
    }, [])
    const getB2cData = async () => {
        setNoData()
        let filter = [
            { param: "region", value: selectedRegion },
            { param: "country", value: country },
            { param: "occupation", value: occupation },
            { param: "income", value: income },
            { param: "gender", value: Gender },
            { param: "age", value: Age },
        ]
        let res = await getB2cSampleSize({payloadData:filter})
        if(res.status === 1){
            // setTotalSampleSize(res.payload.totalSampleSize)
            setTotalSampleSize(changeNumberFormat(res?.payload?.totalSampleSize?.toString()))
        }
    }

    const handleFilter = async (type, value, detail) => {
        let btn = document.getElementById(type + value);
        let data = []
        let select = true;
        switch (type) {
            case "region":
                data = selectedRegion;
                let regionDetail = selectedRegionDetail;
                if (selectedRegion.indexOf(value) === -1) {
                    data.push(value)
                    regionDetail.push(detail)
                    select = true

                } else {
                    data.splice(selectedRegion.indexOf(value), 1)
                    regionDetail.filter((item) => item.id !== value)
                    select = false
                }
                setselectedRegion(data);
                setSelectedRegionDetail(regionDetail);
                getCountries()
                break;

            case "country":
                setCountry(value)
                getB2cData()
                break;

            // gender
            case "gender":
                data = Gender;
                if (Gender.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(Gender.indexOf(value), 1)
                    select = false
                }
                setGender(data);
                break;

            // Age
            case "age":
                data = Age
                if (Age.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(Age.indexOf(value), 1)
                    select = false
                }
                setAge(data);
                break;

            // Occupation
            case "occupation":
                data = occupation
                if (occupation.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(occupation.indexOf(value), 1)
                    select = false
                }
                setOccupation(data);
                break;

            // Income
            case "income":
                data = income
                if (income.indexOf(value) === -1) {
                    data.push(value)
                    select = true
                } else {
                    data.splice(income.indexOf(value), 1)
                    select = false
                }
                setIncome(data);
                break;

            default:
                break;
        }
        if (select) {
            btn.classList.remove("btn-toggle-deselected")
            btn.classList.add("btn-toggle-selected")
        } else {
            btn.classList.remove("btn-toggle-selected")
            btn.classList.add("btn-toggle-deselected")
        }
        getB2cData()
    }

    const handleSampleMethod = (e) => {
        let value = parseInt(e.target.value)
        console.log('value', value)
        console.log('surveySamplingMethod.indexOf(value)', surveySamplingMethod.indexOf(value))
        let data = surveySamplingMethod;
        if (surveySamplingMethod.indexOf(value) === -1) {
            data.push(value)
        } else {
            data.splice(surveySamplingMethod.indexOf(value), 1)
        }
        console.log('data', data)
        setSurveySamplingMethod(data);
    }

    const submitData = async () => {
        let error = false;
        setLevelOfSurveYError()
        setSurveyDurationError()
        setSurveyStartError()
        setSurveyEndError()
        setIrError()
        setSurveyMethodError()
        let startDate = Math.floor(new Date(surveyStartFrom?.toString()).getTime()/1000)
        let endDate = Math.floor(new Date(surveyEndFrom?.toString()).getTime()/1000)
        if(!levelOfSurvey){
            setLevelOfSurveYError(" *Required")
            error = true
        }if(!surveyDuration){
            setSurveyDurationError(" * Required")
            error = true
        }
        if(!surveyStartFrom){
            setSurveyStartError(" * Requied")
            error = true
        }
        if(!surveyEndFrom){
            setSurveyEndError(" * Required")
            error = true
        }
        if(endDate - startDate < 1){
            setSurveyEndError(" * Invalid")
            error = true
        }
        if(!ir){
            setIrError(" * Required")
            error = true
        }
        if(surveySamplingMethod?.length < 1){
            setSurveyMethodError(" * Required")
            error = true
        }
        if(error){
            return error
        }
        let payloadData = {
            regionID: selectedRegion,
            countryID: country,
            genderID: Gender,
            ageGroupID: Age,
            occupationID: occupation,
            incomeGroupID: income,
            surveyLevelID: parseInt(levelOfSurvey),
            surveyDurationID: parseInt(surveyDuration),
            surveyStartDate: startDate,
            surveyEndDate: endDate,
            estimatedIRID: parseInt(ir),
            samplingMethodID: surveySamplingMethod
        }
        let res = await saveB2cData({payloadData:payloadData})
        if(res.status === 1){
            Swal.fire({
                // title: 'Calculating...',
                html:'<p style="color:#031b4e">Your survey data has been uploaded successfully. Our consultant will touch base with the estimation soon.</p>',
                showCancelButton:false,
                showCloseButton: false,
                showConfirmButton:true,
                showDenyButton:false,
                // timer: 1500,
            }).then((result) => {
                if(result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    }

    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">
                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">
                            <div className="side-app">
                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a ><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/SurveySampling.png'></img>Survey Sampling</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a >B2C</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='mb-3'>
                                    <img src='/img/bannerclient/B2C-SURVEY-SAMPLING.jpg' alt='' className='banner' />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 tog">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Region</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {regions?.map((rgn) => {
                                                                return (<>
                                                                <button id={"region" + rgn.id} className={selectedRegion?.indexOf(rgn.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('region', rgn.id, rgn)}>{rgn.name}</button>
                                                                </>)
                                                            })}
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <CheckPicker data={countries} placeholder="Choose Country" groupBy="parentRegion" size="sm" block
                                                            onChange={(e) => handleFilter('country', e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Gender</h3>
                                                    </div>
                                                    <div className="card-body pt-2">

                                                        <div className='togglebtn'>
                                                            {genders?.map((gen) => (<button id={"gender" + gen.id} className={Gender?.indexOf(gen.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('gender', gen.id)}>{gen.name}</button>))}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Age Group</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {ages?.map((age) => (<button id={"age" + age.id} className={Age?.indexOf(age.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('age', age.id)}>{age.name}</button>))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Occupation</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                    <div className='togglebtn'>
                                                    {occupations?.map((occ) => (<button id={"occupation"+occ.id} className={occupation?.indexOf(occ.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('occupation', occ.id)}>{occ.name}</button>))}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="card toggle border-0">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Monthly Household Income</h3>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className='togglebtn'>
                                                            {incomes?.map((inc) => (<button id={"income" + inc.id} className={income?.indexOf(inc.id) === -1 ? "btn btn-toggle btn-toggle-deselected" : "btn btn-toggle btn-toggle-selected"} onClick={() => handleFilter('income', inc.id)}>{inc.name}</button>))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="card border-0">
                                            <div className="card-header">
                                                <h3 className="card-title chart-title">Total Size: <span className='chart-title-num'>{totalSampleSize ? totalSampleSize : ''}</span></h3>
                                            </div>
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <h6 className="task-heading">Level of Survey <span className='errorMessage'> {levelOfSurveYError}</span></h6>
                                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => { setLevelOfSurvey(e.target.value) }} data-placeholder="Select " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select </option>
                                                                {levelOfSurveys?.map((item) => {
                                                                    return <option value={item.id}>{item.name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <hr></hr>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <h6 className="task-heading">Survey Duration in Minutes <span className='errorMessage'> {surveyDurationError}</span></h6>
                                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => { setSurveyDuration(e.target.value) }} data-placeholder="Select SEC " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select Survey Duration</option>
                                                                {surveyDurations?.map((item) => {
                                                                    return <option value={item.id}>{item.name}</option>
                                                                })}

                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-6">
                                                        <div className="form-group form_fill_formcontrol">
                                                            <h6 className="task-heading">Survey Starting Date <span className='errorMessage'> {surveyStartError}</span></h6>
                                                            <input className='form-control' min={format(new Date(), 'yyyy-MM-d').toString()} type="date" onChange={(e) => { setSurveyStartFrom(e.target.value) }}></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group form_fill_formcontrol">
                                                            <h6 className="task-heading">Survey Ending Date <span className='errorMessage'> {surveyEndError}</span></h6>
                                                            <input className='form-control' min={format(new Date(), 'yyyy-MM-d').toString()} type="date" onChange={(e) => { setSurveyEndFrom(e.target.value) }}></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <h6 className="task-heading">Estimated IR <span className='errorMessage'> {irError}</span></h6>
                                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => { setIR(e.target.value) }} data-placeholder="Select SEC " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select </option>

                                                                {irs?.map((item) => {
                                                                    return <option value={item.id}>{item.name}%</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mt-2">
                                                        <div className="form-group">
                                                            <p style={{color:"red", fontSize:"12px", fontWeight:"900"}}> {surveyMethodError}</p>
                                                            <div className='d-flex survey_sampling_checkbox'>
                                                                
                                                                <h6 className="task-heading">Survey Sampling Method:</h6>
                                                                {surveySamplingMethods?.map((item) => {
                                                                    return (<>
                                                                    <input type="checkbox"
                                                                        className='checkbox ml-6 '
                                                                        name="check_tra"
                                                                        value={item.id}
                                                                        onChange={(e) => handleSampleMethod(e)}
                                                                    />
                                                                    <h6 className="task-heading mr-4 ml-1">{item.name}</h6>
                                                                    </>)
                                                                })}
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div className='text-center'>

                                                    <button className="btn btn-primary main-action-button" onClick={submitData}>Express your Interest</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Survey_sampling_b2c