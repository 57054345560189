import { json } from "react-router-dom";
import { postAPI, get } from "../ApiService";

export const getB2bSampleSize = async({payloadData}) =>{
    let res = await postAPI('/survey-sampling/get-sample-size/b2b/', {filters:payloadData})
    return res;
}

export const getB2cSampleSize = async({payloadData}) =>{
    let res = await postAPI('/survey-sampling/get-sample-size/b2c/', {filters:payloadData})
    return res;
}

export const saveB2bData = async({payloadData}) =>{
    let res = await postAPI('/survey-sampling/save-interest/b2b/', payloadData)
    return res;
}
export const saveB2cData = async({payloadData}) =>{
    let res = await postAPI('/survey-sampling/save-interest/b2c/', payloadData)
    return res;
}