import React, { useEffect } from 'react'
import { postAPI } from '../../ApiService'
import { useNavigate } from "react-router-dom";
import { validateToken } from '../../Api/Auth';

function Header() {
    const navigate = useNavigate();
    const userDetail = JSON.parse(localStorage.getItem('userDetail'))
    useEffect(() => {
        let checkLogin = localStorage.getItem('token')
        if(!checkLogin && !userDetail){
            navigate("/");
        }
        checkToken()
    })
    const checkToken = async () =>{
        let res = await validateToken();
        if(res.status === 0){
            localStorage.clear();
            navigate("/");
        }
    }
    const logout = async()=>{
        let res = await postAPI('/auth/logout',{})
        if(res.status === 1){
            localStorage.clear();
            navigate("/");
        }
    }
    return (
        <div className="app sidebar-mini">
            <div className="page">
                <div className="page-main">
                    <div className="">

                        <div className="side-app">
                            <div className="app-header header">
                                <div className="container-fluid">
                                    <div className="d-flex">
                                        <a className="header-brand" href="/">
                                            <img src="img/logo-big.png" className="header-brand-img desktop-lgo" alt="Polly logo" />
                                            <img src="/img/favi.png" className="header-brand-img mobile-logo" alt="Polly logo" />
                                        </a>
                                        <div className="app-sidebar__toggle nav-link icon" data-toggle="sidebar">
                                            <a className="open-toggle" href="">
                                                <span className="fa fa-align-left header-icon"></span>
                                            </a>
                                        </div>
                                        {/* <!-- SEARCH BAR --> */}
                                        {/* <div id="search-bar">
                                            <div>
                                                <a className="nav-link icon">
                                                    <form id="search-field">
                                                        <input type="hidden" name="" value="" />
                                                        <input type="search" name='keyword' />
                                                    </form>
                                                </a>
                                            </div>
                                        </div> */}
                                        {/* <!-- END SEARCH BAR --> */}
                                        {/* <!-- MENU BAR --> */}
                                        <div className="d-flex order-lg-2 ml-auto">
                                            
                                            
                                          
                                            <div className="dropdown profile-dropdown">
                                                
                                                
                                                <a href="/" className="nav-link" data-toggle="dropdown" >
                                                    <span className="float-right d-flex align-items-center " >
                                                    <div className="text-center mx-2">
                                                        <span className=" text-center user fs-12 pb-0 font-weight-bold " style={{color:"black"}}>{userDetail?.firstName}</span>
                                                        {/* <p className="text-center fs-12 text-muted">Administrator</p> */}
                                                      
                                                    </div>
                                                        <div class="avatar" style={{position:"relative"}}>
                                                            <div class="userLetter">{userDetail?.firstName[0]}</div>
                                                            
                                                        </div>
                                                        {/* <img src="https://cloudtextract2.berkine.space/uploads/img/users/7NZWPiZ9XcXX44WZ4Z0j.jpg " alt="img" className="avatar avatar-md" /> */}
                                                    </span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-ce dropdown-menu-arrow animated">
                                                    
                                                    {/* <a className="dropdown-item d-flex" href="/">
                                                        <span className="profile-icon zmdi zmdi-collection-folder-image"></span>
                                                        <div className="fs-12">Administrator</div>
                                                    </a> */}
                                                    
                                                    <a className="dropdown-item d-flex" onClick={() => logout()} style={{cursor:"pointer"}}>
                                                        <span className="profile-icon  mdi mdi-archive"></span>
                                                        <div className="fs-12">Logout</div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- END MENU BAR --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div>
            </div></div>
    )
}

export default Header