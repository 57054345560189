const Segmentcard = {

    card_data:[

       {
        img:"/img/Usersegmentation/1A.png",
        title:"Shopping Orientation & preferences",
        list:[
            {li_val:"Price Buyer"},
            {li_val:"Economy Buyer"},
            {li_val:"Value Buyer"},
            {li_val:"Quality Buyer"},
            {li_val:"Class Buyer"},
            {li_val:"Image Buyer"},
            {li_val:"Functionality Buyer"},
            {li_val:"Style Buyer"},
            {li_val:"Convenience Buyer"},
            {li_val:"Word of Mouth Buyer"},
            {li_val:"Know tow Buyer"},

        ]

       },

       {
        img:"/img/Usersegmentation/2A.png",
        title:"Holidays & Travel",
        list:[
            {
                li_val:"Home Alones",

            },
            {li_val:"Occasional"},
            {li_val:"Regulars"},
            {li_val:"Get-Awayers"},
         

        ]
       },

       {
        img:"/img/Usersegmentation/3A.png",
        title:"Lifestyle Diseases",
        list:[
            {
                li_val:"Low Blood Pressure",

            },
            {li_val:"High Blood Pressure"},
            {li_val:"Diabetes"},
            {li_val:"Thyroid Problem"},
            {li_val:"Arthritis"},
            {li_val:"Chronic"},
            {li_val:"Bronchitis/Asthma"},
            {li_val:"Spondylitis"},
            {li_val:"Obesity"},

            {li_val:"Piles"},
         

        ]
       },
      
       {
        img:"/img/Usersegmentation/4A.png",
        title:"Family composition & lifecycle stage",
        list:[
            {
                li_val:"Single independents",

            },
            {li_val:"Young married couples without any children"},
            {li_val:"3-generation joint family"},
            {li_val:"Middle age "},
            {li_val:"Married couples "},
            {li_val:"Married couple with the youngest child above 12 years"},
            {li_val:"Single parent"},
          
         

        ]
       },
      
       {
        img:"/img/Usersegmentation/5A.png",
        title:"Generational Age groups",
        list:[
            {
                li_val:"Up to 12 Years (Child)",

            },
            {li_val:"13 - 24 Years (Teenager, Young Adult)"},
            {li_val:"25 - 39 Years "},
            {li_val:"Prime Adulthood"},
            {li_val:"40 - 54 Years"},
            {li_val:"55 Years & Above"},
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/6A.png",
        title:"Ability to Spend + Inclination to Spend",
        list:[
            {
                li_val:"Invisibles",

            },
            {li_val:"Modests"},
            {li_val:"Odballs"},
            {li_val:"Frugals"},
            {li_val:"Calculatives"},
            {li_val:"Discreets"},
            {li_val:"Imitators"},
            {li_val:"Ambitious"},
            {li_val:"Flashers"},
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/7A.png",
        title:"The Uppies & The Rich",
        list:[
            {
                li_val:"Climbers (The Upwardly Mobile)",

            },
            {li_val:"Arriveds (The Nouveau Rich)"},
            {li_val:"Richie Richs (The Real Rich with High Net Worth)"},
          
         

        ]
       },
       {
        img:"/img/Usersegmentation/8A.png",
        title:"The Financial Investors",
        list:[
            {
                li_val:"Non-Investing",

            },
            {li_val:"Shallows"},
            {li_val:"Swimmers"},
            {li_val:"Deep Divers"},
            {li_val:"Risk Averse"},
            {li_val:"Prudents"},
            {li_val:"Punters"},
          
         
         

        ]
       },

       {
        img:"/img/Usersegmentation/9A.png",
        title:"Dominant & Integrated Media Usage ",
        list:[
            {
                li_val:"Watchers",

            },
            {li_val:"Readers"},
            {li_val:"Listeners"},
            {li_val:"Surfers"},
            {li_val:"Solo"},
            {li_val:"Binary"},
            {li_val:"3D"},
            {li_val:"Integral"},
          
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/10A.png",
        title:"Chief Wage Earners of the Households",
        list:[
            {
                li_val:"Unskilled Workers",

            },
            {li_val:"Shop Floor Workers"},
            {li_val:"Base Level Managers"},
            {li_val:"Higher Level Managers"},
           
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/11A.png",
        title:"Women Consumers",
        list:[
            {
                li_val:"Women Students",

            },
            {li_val:"Working Maidens"},
            {li_val:"Housewives"},
            {li_val:"Housewife Moms"},
            {li_val:"Working Wives"},
            {li_val:"Working Moms"},

           
         
         

        ]
       },

       {
        img:"/img/Usersegmentation/12A.png",
        title:"Automobiles(Car)",
        list:[
            {
                li_val:"Economy",

            },
            {li_val:"Performance"},
            {li_val:"Mid Size"},
            {li_val:"Premium"},
         
           
         
         

        ]
       },

       {
        img:"/img/Usersegmentation/13A.png",
        title:"Automobiles(Bike)",
        list:[
            {
                li_val:"Economy",

            },
            {li_val:"Performance"},
            {li_val:"Premium"},
            {li_val:"Hi-End"},
         
           
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/14A.png",
        title:"Life, Gen Insurance",
        list:[
           
         
           
         
         

        ]
       },
       {

        img:"/img/Usersegmentation/15A.png",
        title:"Home Durables",
        list:[
            {
                li_val:"Primitive",

            },
            {li_val:"Simplistic"},
            {li_val:"Standard"},
            {li_val:"Modern"},
            {li_val:"Avant- Garde"},
         
           
         
         

        ]
       },

       {
        img:"/img/Usersegmentation/16A.png",
        title:"Alcoholic Drinks",
        list:[
            {
                li_val:"Non-Drinker",

            },
            {li_val:"Habitual Drinker"},
            {li_val:"Casual Drinker"},
            {li_val:"Social Drinker"},
            {li_val:"Hardies"},
            {li_val:"Milds"},
         
           
         
         

        ]
       },

       {
        img:"/img/Usersegmentation/17A.png",
        title:"Cigarettes",
        list:[
            {
                li_val:"Non-Smoker",

            },
            {li_val:"Habitual Smoker"},
            {li_val:"Casual Smoker"},
            {li_val:"Social Smoker"},
         
           
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/18A.png",
        title:"Personal Care",
        list:[
            {
                li_val:"Non-Groomer",

            },
            {li_val:"Basic Groomer"},
            {li_val:"Moderate Groomer "},
            {li_val:"Intense Groomer"},
            {li_val:"Functional Groomer"},
            {li_val:"Appearance Groomer "},
            {li_val:"Lifestyle Groomer"},
         
           
         
         

        ]
       },
       {
        img:"/img/Usersegmentation/19A.png",
        title:"Processed Food",
        list:[
            {
                li_val:"Non Pack Foodie HH",

            },
            {li_val:"Basic Pack Foodie HH"},
            {li_val:"Moderate Pack Foodie HH "},
            {li_val:"Intense Pack Foodie HH"},
            {li_val:"Snacking Pack Foodie"},
            {li_val:"Functional Pack Foodie "},
            {li_val:"Lifestyle Pack Foodie"},
         
           
         
         

        ]
       },
    ]
}

export default Segmentcard