import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { postAPI } from '../ApiService';
import FPVerifyOTP from "./ForgotPassword_VerifyOTP";

function ForgotPassword() {
    const navigate = useNavigate();
    const initialValues = { password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [error, setErrors] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    
    useEffect(() => {
        if(localStorage.getItem('token') && localStorage.getItem('userDetail')){
            navigate("/dashboard");
        }
    })
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const onSubmit = (e) => {
        // e.preventDefault();
        setErrors();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }
    
    useEffect(() => {
        const submitSendOtp = async () => {
            if (Object.keys(formErrors).length === 0 && isSubmit) {
                let data = {
                    email : formValues.email,
                    source: "web"
                }
                let res = await postAPI('/user/forgot-password/send-otp', data);
                if(res.status === 1){
                    setFormValues({ ...formValues, accessToken: res.payload.accessToken, _token:res.payload.token });
                    setIsOtpSent(true)
                } else{
                    setErrors(res.message)
                }
            }
        }
        submitSendOtp()
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regex = /^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/
        let IndNumberRegEx = /^((\+91)?|91)?[789][0-9]{9}/;
        if (!values.email) {
            errors.email = "Required Field!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        return errors;
    };
    useEffect(() => {
        document.title = "Forgot Password | Mindforce"
    }, []);

    const backToFPg = () =>{
        setIsOtpSent(false)
    }

    return (
        <div className="page-main">
            <div className="main-content">
                <div className="side-app">
                    <div className="container-fluid justify-content-center">
                        <div className="row h-100vh align-items-center background-white">
                            <div className="col-lg-5 col-md-12 col-sm-12 h-100" id="login-responsive">
                                <div className="card-body pr-9 pl-9 pt-7">
                                    <img src='/img/logo-big.png' className='mb-4'></img>
                                    <div className="card border-0">
                                        {isOtpSent ? (<>
                                        <FPVerifyOTP formValues={formValues} backToInfo={backToFPg} />
                                        </>) : (<>
                                            <div className="card-body  pb-4 pt-0 sign">
                                            <div className="divider">
                                                <div className="divider-text text-muted">
                                                    <small>FORGOT PASSWORD</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                            {error ? (<>
                                                <div className="col-lg-12">
                                                    <div className='errorMessageform'>{error}</div>
                                                    <br /><br />
                                                </div>
                                                </>) : (<></>)}
                                            </div>
                                            <div className="input-box mb-5">
                                                <label for="email" className="fs-12 font-weight-bold text-md-right  mb-0">Enter your registered email address <span className="required_f">*</span><span className='errorMessageform'> {formErrors.email}</span></label>
                                                <input id="email" type="email" className="form-control " name="email" autocomplete="off" placeholder="Please enter your registered email address"  value={formValues.email} onChange={handleChange} required />
                                            </div>
                                            <div className="form-group">
                                                <button onClick={() => onSubmit()} className="btn btn-primary mr-2">Next</button>

                                            </div>
                                            <div className="form-group mt-3">
                                                <div className="">
                                                    <label className="custom-switch">
                                                        <span className="custom-switch-description">Don't have an account?</span> <a className="text-info fs-12" href="/sign-up">Sign Up</a>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12 col-sm-12 text-center background-special h-100 align-middle pl-0 pr-0" id="login-background">
                                <div className="login-bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword;