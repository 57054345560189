import { json } from "react-router-dom";
import { postAPI, get } from "../ApiService";

export const sendOTP = async(data) => {
    let res = await postAPI('/auth/signup/verify-info',data)
    return res;
}

export const saveGeoDetail = async(data) => {
    let res = await postAPI('/auth/signup/save-geo-info',data)
    return res;
}

export const savePersonalDetail = async(data) => {
    let res = await postAPI('/auth/signup/save-professional-info',data)
    return res;
}

export const validateToken = async() => {
    let res = await postAPI('/auth/check-token/')
    return res;
}