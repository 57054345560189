import './Theme.scss'
import './App.css'
import './form.scss'
import 'rsuite/dist/rsuite.min.css';
import React from 'react'
import {
  Routes, Route, Navigate
} from 'react-router-dom';
import Business_to_Business from './component/panel_book/Business-to-Business';
import Business_to_Consumer from './component/panel_book/Business-to-Consumer';
import Retail from './component/panel_book/Retail';
import Login_page from './component/Login_page';
import Forgot_password_page from './component/Forgot_password_page';
import Usage_habits from './component/usage_habit/Usage_habits';
import Dashboard from './component/Dashboard/Dashboard';
//import "react-awesome-lightbox/build/style.css";


import Geo_info from './component/Sign_up/Geo_info';
import Geo_detail from './component/Sign_up/Geo_detail';
import Professional_info from './component/Sign_up/Professional_info';
import Survey_scripting_page from './component/Survey_scripting/Survey-scripting-page';
import Survey_sampling_b2b from './component/Survey_sampling/Survey_sampling';
import Survey_sampling_b2c from './component/Survey_sampling/Survey_sampling_b2c';
import Survey_sampling_retail from './component/Survey_sampling/Survey_sampling_retail';
import Sample from './component/usage_habit/Usage_habits';
import Data_processing from './component/Dataprocessing/Data_proccessing';
import Data_analysis from './component/Data_analysis/Data_analysis';
import Statistical_modelling from './component/Statistical_modelling/Statistical_modelling';
import Charting from './component/Charting/Charting';
import Brand_score_card from './component/Brand_score/Brand_score_card';
import Size_estimation from './component/Size_e/Size_estimation';
import User_segmentation from './component/User_segmentation/User_segmentation';
import Case_study from './component/Case_study/Case_study';
import Case_study_detail from './component/Case_study/Case_study_detail';
import Settings from './component/Settings/Settings';
import Admin from './component/Admin/Admin';
import ScrollToTop from './component/ScrollToTop';
import NotFound from './component/NotFound';

// static pages of cases 
import DrivingAdoptionOFAiAlignedWithBusinessGoals from './component/Case_study/Driving_adoption_of_aI_aligned_with_business_goals';
import The_evolution_of_mobile_transport_while_transitioning_from_4G_to_5G from './component/Case_study/The_evolution_of_mobile_transport_while_transitioning_from_4G_to_5G';
import The_evolving_impact_of_the_digital_world_across_different_functions_and_industries from './component/Case_study/The_evolving_impact_of_the_digital_world_across_different_functions_and_industries';
import A_closer_look_at_sd_wan_technologies from './component/Case_study/A_closer_look_at_sd_wan_technologies';
import Simplify_recruiting_with_the_applicant_tracking_system from './component/Case_study/Simplify_recruiting_with_the_applicant_tracking_system';
import The_role_of_risk_management_technologies from './component/Case_study/The_role_of_risk_management_technologies';
import Stay_a_step_ahead_of_fraudulent_threats from './component/Case_study/Stay_a_step_ahead_of_fraudulent_threats';
import Whats_trending_in_cyber_security from './component/Case_study/Whats_trending_in_cyber_security';
import Confused_About_blockchain from './component/Case_study/Confused_About_blockchain';
import How_businesses_can_leverage_big_data from './component/Case_study/How_businesses_can_leverage_big_data';
import Deploying_ar_in_manufacturing from './component/Case_study/Deploying_ar_in_manufacturing';
import Travel_destinations_that_are_in_demand from './component/Case_study/Travel_destinations_that_are_in_demand';
import Get_a_sip_of_bartenders_study from './component/Case_study/Get_a_sip_of_bartenders_study';
import Living_the_luxe_life from './component/Case_study/Living_the_luxe_life';
import Gear_up_for_smart_education from './component/Case_study/Gear_up_for_smart_education';
import What_drives_consumers_to_buy_mobile_phones from './component/Case_study/What_drives_consumers_to_buy_mobile_phones';
import Where_is_your_next_destination from './component/Case_study/Where_is_your_next_destination';
import Importance_of_healthcare_professionals_of_tomorrow from './component/Case_study/Importance_of_healthcare_professionals_of_tomorrow';
import Deepening_the_understanding_of_trends_in_e_commerce from './component/Case_study/Deepening_the_understanding_of_trends_in_e_commerce';
import Covid_19_and_changing_workforce_strategies from './component/Case_study/Covid_19_and_changing_workforce_strategies';
import Sample_distribution from './component/Sample_distribution/Sample_distribution';

// import { Refreshable } from 'react-router-refreshable'

function App() {
  return (
    <>


      <ScrollToTop />




      <Routes>
        <Route exact path='/' element={<Login_page />} />


        <Route path='/sign-up' element={<Geo_info />} />
        <Route path='/sign-up/geo-info' element={<Geo_detail />} />
        <Route path='/sign-up/professional-info' element={<Professional_info />} />
        <Route path='/forgot-password' element={<Forgot_password_page />} />
        <Route path='/business-to-business' element={<Business_to_Business />} />
        <Route path='/business-to-consumers' element={<Business_to_Consumer />} />
        <Route path='/retail' element={<Retail />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/usage-habits' element={<Usage_habits />} />
        <Route path='/survey-sampling/b2b' element={<Survey_sampling_b2b />} />
        <Route path='/survey-sampling/b2c' element={<Survey_sampling_b2c />} />
        <Route path='/surveysampling/retail' element={<Survey_sampling_retail />} />
        <Route path='/sample-distribution' element={<Sample_distribution />} />
        <Route path='/survey-scripting' element={<Survey_scripting_page />} />
        <Route path='/data-modeling' element={<Data_processing />} />
        <Route path='/data-analysis' element={<Data_analysis />} />
        <Route path='/statistical-modelling' element={<Statistical_modelling />} />
        <Route path='/charting' element={<Charting />} />
        <Route path='/Brand Track' element={<Brand_score_card />} />
        <Route path='/size-estimation' element={<Size_estimation />} />
        <Route path='/user-segmentation' element={<User_segmentation />} />
        <Route path='/case-study' element={<Case_study />} />
        
        <Route path='/settings' element={<Settings />} />
        <Route path='/admin' element={<Admin />} />
        <Route path="notfound" element={<NotFound />} />

        <Route path="*" element={<Navigate to="/notfound" replace />} />
        {/* case study detail pages */}
        <Route path='/cases-detail/driving-adoption-of-aI-aligned-with-business-goals' element={<DrivingAdoptionOFAiAlignedWithBusinessGoals />} />
        <Route path='/cases-detail/the-evolution-of-mobile-transport-while-transitioning-from-4G-to-5G' element={<The_evolution_of_mobile_transport_while_transitioning_from_4G_to_5G />} />
        <Route path='/cases-detail/the-evolving-impact-of-the-digital-world-across-different-functions-and-industries' element={<The_evolving_impact_of_the_digital_world_across_different_functions_and_industries />} />
        <Route path='/cases-detail/a-closer-look-at-sd-wan-technologies' element={<A_closer_look_at_sd_wan_technologies  />} />
        <Route path='/cases-detail/simplify-recruiting-with-the-applicant-tracking-system' element={<Simplify_recruiting_with_the_applicant_tracking_system  />} />
        <Route path='/cases-detail/the-role-of-risk-management-technologies' element={<The_role_of_risk_management_technologies  />} />
        <Route path='/cases-detail/stay-a-step-ahead-of-fraudulent-threats' element={<Stay_a_step_ahead_of_fraudulent_threats />} />
        <Route path='/cases-detail/whats-trending-in-cyber-security' element={<Whats_trending_in_cyber_security />} />
        <Route path='/cases-detail/confused-about-blockchain' element={<Confused_About_blockchain />} />
        <Route path='/cases-detail/how-businesses-can-leverage-big-data' element={<How_businesses_can_leverage_big_data />} />
        <Route path='/cases-detail/deploying-ar-in-manufacturing' element={<Deploying_ar_in_manufacturing />} />
        <Route path='/cases-detail/travel-destinations-that-are-in-demand' element={<Travel_destinations_that_are_in_demand />} />
        <Route path='/cases-detail/get-a-sip-of-bartenders-study' element={<Get_a_sip_of_bartenders_study />} />
        <Route path='/cases-detail/living-the-luxe-life' element={<Living_the_luxe_life />} />
        <Route path='/cases-detail/gear-up-for-smart-education' element={<Gear_up_for_smart_education />} />
        <Route path='/cases-detail/what-drives-consumers-to-buy-mobile-phones' element={<What_drives_consumers_to_buy_mobile_phones />} />
        <Route path='/cases-detail/where-is-your-next-destination' element={<Where_is_your_next_destination />} />
        <Route path='/cases-detail/importance-of-healthcare-professionals-of-tomorrow' element={<Importance_of_healthcare_professionals_of_tomorrow />} />
        <Route path='/cases-detail/deepening-the-understanding-of-trends-in-e-commerce' element={<Deepening_the_understanding_of_trends_in_e_commerce />} />
        <Route path='/cases-detail/covid-19-and-changing-workforce-strategies' element={<Covid_19_and_changing_workforce_strategies />} />
      </Routes>






    </>
  );
}

export default App;
