import React from 'react'
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import { useNavigate } from "react-router-dom";

function NotFound() {


  const navigate = useNavigate();
  const Back_dashboard = async () => {
    navigate("/dashboard")

  }

return (
  <>
    <Sidebar />
    <Header />

    <div className="app sidebar-mini Tabulation_sec">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app ">


    <div className='text-center NotFound '>
      <img src='https://png.pngitem.com/pimgs/s/106-1063145_oops-speech-balloon-transparent-hd-png-download.png' />
      <h3>404 page not found</h3>
      <p>We are sorry but the page you are looking for does not exist.</p>

      <button className="btn btn-primary main-action-button NotFound_btn mt-4" onClick={Back_dashboard} >Back To Dashboard</button>
                                                   
    </div>

    </div>
    </div>
    </div>
    </div>
    </div>

  </>
)
}

export default NotFound