import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function Covid_19_and_changing_workforce_strategies() {


    useEffect(() => {

        document.title = "COVID-19 and changing workforce strategies - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-20.jpg" className='img-fluid' alt="cases" />
                                                </div>
                                                <h1 className='h2 mt-4'>COVID-19 and changing workforce strategies</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-content">
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Objective</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>There has been a significant shift in the overall strategies of organizations in every industry. Therefore, A global market research firm wanted to know how organizations are coping with the COVID-19 challenges in terms of remote working.</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Methodology</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            Online                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Respondents</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <ul>
                                                                <li>CIO/VP/Director IT</li>
                                                                <li>CMO/VP/Director Marketing</li>
                                                                <li>CHRO/VP/Director HR</li>
                                                            </ul>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Sample</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            700 interviews                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Geography</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            North America, Europe and APAC                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Result</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The study examined challenges, how they are being addressed, the efficacy of remedial solutions and the areas of greatest concern.  The study also examined what has worked well, how this may impact future preferences, and how the adoption of technological solutions has been accelerated.</p>                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Covid_19_and_changing_workforce_strategies
