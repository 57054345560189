import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function Importance_of_healthcare_professionals_of_tomorrow() {


    useEffect(() => {

        document.title = "Where is your Next Destination? - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-18.jpg" className='img-fluid' alt="cases" />
                                                </div>
                                                <h1 className='h2 mt-4'>Importance of healthcare professionals of tomorrow</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-content">
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Objective</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The research aim was to understand the growth of the nursing education market and its increasing demand in coming years and the various opportunities and practices given to the students by nursing schools and colleges.</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Methodology</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Online Method</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Respondents</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Students of Nursing or Health Professions currently or in last year</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Sample</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>400 interviews</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Geography</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>North America and Europe</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Result</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The research results enabled the client to actively consult with nursing schools on how to understand the nursing education ecosystem to optimize growth while attracting and placing superior students.</p>                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Importance_of_healthcare_professionals_of_tomorrow