import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Segmentcard from '../../Segmentcard';
import Multiselect from 'multiselect-react-dropdown';


function Sample_distribution() {
    const [selectedRadiosB2b, setSelectedRadiosB2b] = useState([]);
    const [selectedRadiosB2c, setSelectedRadiosB2c] = useState([]);
    //const [selectedValue, setSelectedValue] = useState([]);    
    const [isDisableB2b, setIsDisableB2b] = useState(false);
    const [isDisableB2c, setIsDisableB2c] = useState(false);

    const handleRadioChangeB2b = (selectedList,index) => {
        if(isDisableB2b === false){
            let tempList = [];                              
            if(selectedList && selectedList.length > 0){
                for(let i=0; i < selectedList.length; i++){
                    tempList[i] = selectedList[i].id
              }
                setIsDisableB2c(true);
            }else{
                setIsDisableB2c(false);
              }
            setSelectedRadiosB2b(tempList);
            console.log(tempList)
        }
        
         
    };
   //console.log(isDisableB2c);
   const handleRadioChangeB2c = (selectedList,index) => {
        if(isDisableB2c === false){
        let tempList = [];                              
        if(selectedList && selectedList.length > 0){
            for(let i=0; i < selectedList.length; i++){
                tempList[i] = selectedList[i].id
              }
                setIsDisableB2b(true);
        }else{
                setIsDisableB2b(false);
              }
        setSelectedRadiosB2c(tempList);
        console.log(tempList)
            }
      };
   
  
    let b2bOptions = {
        options: [
            {name: 'By Industry', id: '1'},
            {name: 'By Employee Size', id: '2'},
            {name: 'By Revenue', id: '3'},
            {name: 'By Job Title', id: '4'},
            {name: 'By Department', id: '5'},
        ]
    };
    let b2cOptions = {
        options: [
            {name: 'By Gender', id: '1'},
            {name: 'By Age', id: '2'},
            {name: 'By Occupation', id: '3'},
            {name: 'By SEC', id: '4'},
        ]
    };



    useEffect(() => {

        document.title = "User Segmentation - Mindforce"



    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5">
                                    <div className="page-leftheader">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a > <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Sample-Distribution.png'></img>Sample Distribution</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <section className='sample-distribution-sec'>
                                    <div className='row'>
                                        <div className='col-lg-12 mb-4'>
                                            <div className='sample-distribution-sidebar'>
                                                <div className='row'>
                                                <div className={`col-lg-6  b2b-b2c-box ${isDisableB2b ? 'disabled': ''}` }  >
                                                    <h3 className=''>B2B <span  className='h6'>Select any three</span></h3>
                                                    <Multiselect disable={isDisableB2b} 
                                                        options={b2bOptions.options} // Options to display in the dropdown
                                                        //selectedValues={selectedValue} // Preselected value to persist in dropdown
                                                        onSelect={handleRadioChangeB2b} // Function will trigger on select event
                                                        onRemove={handleRadioChangeB2b} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        value={b2bOptions.options.id}                                                            
                                                             />
                                                        </div>
                                                <div  className={`col-lg-6 b2b-b2c-box  ${isDisableB2c ? 'disabled': ''}`} >
                                                    <h3 className=''>B2C <span  className='h6'>Select any three</span></h3>
                                                    <Multiselect disable={isDisableB2c}
                                                        options={b2cOptions.options} // Options to display in the dropdown
                                                        //selectedValues={selectedValue} // Preselected value to persist in dropdown
                                                        onSelect={handleRadioChangeB2c} // Function will trigger on select event
                                                        onRemove={handleRadioChangeB2c} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        value={b2cOptions.options.id}                                                            
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='selected-items bg-light p-3'>
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('1') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Industry & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-success table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-success'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Industry</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>Business & Consumer services</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>BFSI</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('2') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Employee & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-warning table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-warning'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Employee Size</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>1 to 259</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>250 to 499</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('3') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Revenue & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-danger table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-danger'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Revenue</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>C-Level</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SVP/VP/EVP</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('4') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Title & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-primary table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-secondary'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Job Title</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>C-Level</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SVP/VP/EVP</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                
                                                <div className={`selected-item-box ${selectedRadiosB2b.includes('5') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Department & enter your required sample</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-primary table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-primary'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Department</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>C-Level</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SVP/VP/EVP</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('1') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Gender </h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-warning table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-warning'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Gender</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>Male</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>Female</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('2') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Age Group </h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-info table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-info'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Age Group</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>15-17 Years</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>18-24 Years</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('3') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Occupation </h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-success table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-success'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">Occupation</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td>Student</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>Home Maker</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                {/* selected-item-box end  */}
                                                <div className={`selected-item-box ${selectedRadiosB2c.includes('4') ? 'd-block' : 'd-none'}`}>
                                                    <h3 className='h6'>Select Socio Economic Classification</h3>
                                                    <div className='table-responsive'>
                                                        <table class="table table-bordered table-striped table-danger table-sm">
                                                            <thead className='text-center'>
                                                                <tr className='bg-danger'>
                                                                    <th scope="col">Select</th>
                                                                    <th scope="col">SEC</th>
                                                                    <th scope="col">Sample</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio1" id="radio1" />
                                                                        </div>
                                                                    </th>
                                                                    <td style={{width:"50px"}}> SEC A</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SEC B</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SEC C</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SEC D</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                <tr>
                                                                <th scope="row">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input" type="checkbox" name="radio2" id="radio2" />
                                                                        </div>
                                                                    </th>                                                             
                                                                    <td>SEC E</td>
                                                                    <td><input type="text" className='w-100'/></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* selected-item-box end  */}
                                                
                                            </div>
                                        </div>
                                        <div className='col-lg-9'>
                                            <div className='table-box-main'>
                                                
                                                <div className='table-responsive'>
                                                    <table class="table table-bordered table-sm">
                                                        <thead className='text-center'>
                                                            <tr className='bg-blue'>
                                                                <td colSpan='3'>
                                                                    <h3 className='h4 m-0'>Total Sample: 500</h3>
                                                                </td>
                                                                 <td colSpan='6'>
                                                                    <h3 className='h4 m-0'>Employee Size</h3>
                                                                 </td>
                                                            </tr>
                                                            <tr className='bg-light'>
                                                                <th scope="col">Employee Size</th>
                                                                <th scope="col">Title</th>
                                                                <th scope="col">Total</th>
                                                                <th scope="col">Business & Services</th>
                                                                <th scope="col">BFSI</th>
                                                                <th scope="col">Electronics</th>
                                                                <th scope="col">Manufactring</th>
                                                                <th scope="col">Telecom & media</th>
                                                                <th scope="col">Energy & Utilities</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='bg-success'>
                                                                <th scope="row">1 to 259</th>
                                                                <td>Sample Distribution</td>
                                                                <td>50</td>
                                                                <td>10</td>
                                                                <td>13</td>
                                                                <td>5</td>
                                                                <td>5</td>
                                                                <td>10</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row"></th>
                                                                <td>c-Level</td>
                                                                <td>3</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>1</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row"></th>
                                                                <td>SVP/VP/EVP</td>
                                                                <td>3</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>1</td>
                                                                <td>8</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Sample_distribution