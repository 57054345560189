
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { checkExpiry } from "../../Common/ExpiryTimeCheck";
import { getMasterData } from "../../Api/MasterData";
import { savePersonalDetail, validateToken } from "../../Api/Auth";

function Professional_info() {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [compayName, setCompanyName] = useState();
    const [titles, setTitles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [revenueSizes, setRevenueSizes] = useState([]);
    const [employeeSizes, setEmployeeSizes] = useState([]);

    const [title, setTitle] = useState();
    const [department, setDepartment] = useState();
    const [industry, setIndustry] = useState();
    const [revenueSize, setRevenueSize] = useState();
    const [employeeSize, setEmployeeSize] = useState();

    const [error, SetError] = useState();
    const [formErrors, setFormErrors] = useState({})
    const [companyError, setCompanyError] = useState();
    const [titleError, setTitleError] = useState();
    const [departmentError, setDepartmentError] = useState();
    const [industryError, setIndustryError] = useState();
    const [revenueError, setReveueError] = useState();
    const [employeeError, setEmployeeError] = useState();

    useEffect(() => {
        if(!token){
            navigate("/");
        }
        checkToken()
    },[])

    const checkToken = async () =>{
        let res = await validateToken();
        if(res.status === 0){
            localStorage.clear();
            navigate("/");
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('titles')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getTitles();
            } else {
                data = JSON.parse(localStorage.getItem('titles'))
                if (data.status === 1) {
                    setTitles(data.payload);
                }
            }
        }

    }, [])
    const getTitles = async () => {
        let data = await getMasterData({ moduleName: 'titles', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setTitles(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('departments')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getDepartments();
            } else {
                data = JSON.parse(localStorage.getItem('departments'))
                if (data.status === 1) {
                    setDepartments(data.payload);
                }
            }
        }

    }, [])
    const getDepartments = async () => {
        let data = await getMasterData({ moduleName: 'departments', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setDepartments(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('industry')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getIndustries();
            } else {
                data = JSON.parse(localStorage.getItem('industry'))
                if (data.status === 1) {
                    setIndustries(data.payload);
                }
            }
        }

    }, [])
    const getIndustries = async () => {
        let data = await getMasterData({ moduleName: 'industry', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setIndustries(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('revenue-size')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getRevenueSize();
            } else {
                data = JSON.parse(localStorage.getItem('revenue-size'))
                if (data.status === 1) {
                    setRevenueSizes(data.payload);
                }
            }
        }

    }, [])
    const getRevenueSize = async () => {
        let data = await getMasterData({ moduleName: 'revenue-size', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setRevenueSizes(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('employee-size')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getEmployeeSize();
            } else {
                data = JSON.parse(localStorage.getItem('employee-size'))
                if (data.status === 1) {
                    setEmployeeSizes(data.payload);
                }
            }
        }

    }, [])
    const getEmployeeSize = async () => {
        let data = await getMasterData({ moduleName: 'employee-size', pagePerRec: 250, page: 1 })
        if (data.status === 1) {
            setEmployeeSizes(data.payload);
        }
    }

    const validate = (value) => {
        const errors = {};
        if(!value.companyName){
            errors.companyName = "Required Field!"
        }
        if(!value.titleId){
            errors.titleId = "Required Field!"
        }
        if(!value.departmentId){
            errors.departmentId = "Required Field!"
        }
        if(!value.industryId){
            errors.industryId = "Required Field!"
        }
        if(!value.revenueSizeId){
            errors.revenueSizeId = "Required Field!"
        }
        if(!value.employeeSizeId){
            errors.employeeSizeId = "Required Field!"
        }
        return errors;
    }

    const onUsersignlast = async () => {
        let data = {
            companyName: compayName,
            titleId: parseInt(title),
            departmentId: parseInt(department),
            industryId: parseInt(industry),
            revenueSizeId: parseInt(revenueSize),
            employeeSizeId: parseInt(employeeSize)
        }
        console.log('data', data)
        let valid = validate(data);
        console.log('valid', valid)
        if(valid.companyName || valid.titleId || valid.departmentId || valid.industryId || valid.revenueSizeId || valid.employeeSizeId){
            return setFormErrors(valid);
        }
        
        let res = await savePersonalDetail(data);
        if(res.status === 1){
            localStorage.setItem('isLogin', true);
            localStorage.setItem('userDetail', JSON.stringify(res.payload))
            navigate("/dashboard")
        }else{
            SetError(res.message);
        }
        
    }
    useEffect(() => {
        document.title = "Sign Up - Mindforce"
    }, []);

    return (
        <div className="page-main">

            {/* <!-- App-Content -->			 */}
            <div className="main-content">
                <div className="side-app">

                    <div className="container-fluid justify-content-center">
                        <div className="row h-100vh align-items-center background-white">
                            <div className="col-lg-5 col-md-12 col-sm-12 h-100" id="login-responsive">
                                <div className="card-body pr-9 pl-9 pt-7">
                                    <img src='/img/logo-big.png' className='mb-4'></img>
                                        <div className="card border-0 sign">
                                            <div className="card-body pb-4 pt-0">
                                                <div className="divider">
                                                    <div className="divider-text text-muted">
                                                        <small> Professional Profile </small>
                                                    </div>
                                                </div>
                                                {error ? (<>
                                                    <div className="col-lg-12">
                                                        <div className='errorMessageform'>{error}</div>
                                                        <br /><br />
                                                    </div>
                                                </>) : (<></>)}
                                                <div className="input-box mb-2">
                                                    <label for="companyName" className="fs-12 font-weight-bold text-md-right  mb-0">Company Name <span className="required_f">*</span><span className='errorMessage'> {formErrors.companyName}</span></label>
                                                   <input id="companyName" type="text" className="form-control " name="Company" placeholder="Enter Company Name" onChange={(e) => setCompanyName(e.target.value)} />
                                                </div>

                                                <div className="input-box mb-2">
                                                    <label for="designation" className="fs-12 font-weight-bold text-md-right  mb-0">Designation / Title <span className="required_f">*</span><span className='errorMessage'> {formErrors.titleId}</span></label>
                                                    <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" data-callback="userEnableTable" onChange={(e) => setTitle(e.target.value)}>
                                                        <option value={""} disabled selected>Select Title</option>
                                                        {titles?.map((tit) => {
                                                            return (<>
                                                                <option value={tit.id}>{tit.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                                {/* <div className="input-box mb-2">
                                                    <label for="company" className="fs-12 font-weight-bold text-md-right  mb-0">Department <span className="required_f">*</span><span className='errorMessage'> {formErrors.departmentId}</span></label>
                                                    <select id="department" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" data-callback="userEnableTable"  onChange={(e) => setDepartment(e.target.value)}>
                                                        <option value={""} disabled selected>Select Department</option>
                                                        {departments?.map((dep) => {
                                                            return (<>
                                                                <option value={dep.id}>{dep.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div> */}
                                                
                                                <div className="input-box mb-2">
                                                    <label className="fs-12 font-weight-bold text-md-right  mb-0">Department <span className="required_f">*</span><span className='errorMessage'> {formErrors.departmentId}</span></label>
                                                    <select className="form-select" data-placeholder="Select Department" data-callback="userEnableTable" onChange={(e) => setDepartment(e.target.value)}>
                                                        <option value={""} disabled selected>Select Department</option>
                                                        {departments?.map((dep) => {
                                                            return (<>
                                                                <option value={dep.id}>{dep.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="input-box mb-2">
                                                    <label for="Industry" className="fs-12 font-weight-bold text-md-right  mb-0">Industry <span className="required_f">*</span><span className='errorMessage'> {formErrors.industryId}</span></label>
                                                    <select id="industry" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" data-callback="userEnableTable" onChange={(e) => setIndustry(e.target.value)}>
                                                        <option value={""} disabled selected>Select Industry</option>
                                                        {industries?.map((ind) => {
                                                            return (<>
                                                                <option value={ind.id}>{ind.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="input-box mb-2">
                                                    <label for="industrySizeByRevenue" className="fs-12 font-weight-bold text-md-right  mb-0">Industry Size by Revenue <span className="required_f">*</span><span className='errorMessage'> {formErrors.revenueSizeId}</span></label>
                                                    <select id="industrySizeByRevenue" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" data-callback="userEnableTable" onChange={(e) => setRevenueSize(e.target.value)}>
                                                        <option value={""} disabled selected>Select Size by Revenue</option>
                                                        {revenueSizes?.map((rev) => {
                                                            return (<>
                                                                <option value={rev.id}>{rev.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="input-box ">
                                                    <label for="country" className="fs-12 font-weight-bold text-md-right  mb-0 ">Employee Size <span className="required_f">*</span><span className='errorMessage'> {formErrors.employeeSizeId}</span></label>

                                                    <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" data-callback="userEnableTable" onChange={(e) => setEmployeeSize(e.target.value)}>
                                                        <option value={""} disabled selected>Select Employee Size</option>
                                                        {employeeSizes?.map((emp) => {
                                                            return (<>
                                                                <option value={emp.id}>{emp.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <button onClick={() => onUsersignlast()} className="btn btn-primary mr-2">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 text-center background-special h-100 align-middle pl-0 pr-0" id="login-background">
                                <div className="login-bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Professional_info