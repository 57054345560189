import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect } from 'react';
import Data from '../../Form_Data';

function Survey_sampling_retail() {
    useEffect(() => {

        document.title = "Survey Sampling Retail - Mindforce"



    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                        {/* <h4 className="page-title mb-0">Survey Sampling</h4> */}
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/SurveySampling.png'></img>Survey Sampling</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a >Retail</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='gif_comingsoon'>

<img src='/img/coming-soon.png'/>

</div>
                                {/* <div className='mb-3'>
                                <img src='/img/USAGEBAHITS.jpg' alt='' className='banner'/>

                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <form id="textract-form" >
                                            <input type="hidden" />


                                            <div className="card border-0">
                                                <div className="card-body p-5 pb-0">

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">City</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select City" >
                                                                    <option value={""} disabled selected>Select City</option>

                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Country</h6>
                                                                <input id="India" type="text" className="form-control input_hight" name="India" value="India" readonly />



                                                            </div>
                                                        </div>



                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Survey Type</h6>
                                                                <input id="Retail" type="text" className="form-control input_hight" name="Retail" value="Retail" readonly />

                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Shop Type</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select" data-callback="userEnableReceipt">
                                                                    <option value={""} disabled selected>Select Shop Type  </option>
                                                                    {Data.Shop_Type.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">No. of Employee</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " >
                                                                    <option value={""} disabled selected>Select </option>
                                                                    {Data.No_of_shop_Employee.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Monthly Transaction</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select  " >
                                                                    <option value={""} disabled selected>Select </option>
                                                                    {Data.Monthly_Transaction_retail.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">How old is Your Shop</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select  " >
                                                                    <option value={""} disabled selected>Select </option>
                                                                    {Data.How_old_is_your_shop.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Service Type</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " >
                                                                    <option value={""} disabled selected>Select</option>
                                                                    {Data.Service_Type.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>



                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Label of Survey</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " data-callback="userEnableReceipt">
                                                                    <option value={""} disabled selected>Select </option>
                                                                    {Data.Label_of_Survey.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <hr></hr>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Survey Duration in Minutes</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select  " data-callback="userEnableReceipt">
                                                                    <option value={""} disabled selected>Select Survey Duration</option>
                                                                    {Data.Survey_Duration.map((item) => {
                                                                        return <option value={item.value}>{item.label}</option>
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-6">
                                                            <div className="form-group form_fill_formcontrol">
                                                                <h6 className="task-heading">Survey Starting Date</h6>
                                                                <input className='form-control' type="date"></input>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group form_fill_formcontrol">
                                                                <h6 className="task-heading">Survey Ending Date</h6>
                                                                <input className='form-control' type="date"></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 mt-4">
                                                            <div className="form-group">

                                                                <div className='d-flex survey_sampling_checkbox'>
                                                                    <h6 className="task-heading">Survey Sampling Method:</h6>
                                                                    <input type="checkbox"
                                                                        className='checkbox ml-6 '
                                                                        name="check_tra"

                                                                    />
                                                                    <h6 className="task-heading mr-4 ml-1">CATI</h6>

                                                                    <input type="checkbox"
                                                                        className='checkbox ml-4 '
                                                                        name="check"

                                                                    />
                                                                    <h6 className="task-heading mr-4 ml-1">Online</h6>
                                                                    <input type="checkbox"
                                                                        className='checkbox ml-4 '
                                                                        name="check"

                                                                    />
                                                                    <h6 className="task-heading  ml-1">FTF</h6>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Estimated IR</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select SEC " data-callback="userEnableReceipt">
                                                                    <option value={""} disabled selected>Select </option>

                                                                    {Data.IR.map((item) => {
                                                                        return <option value={item}>{item}%</option>
                                                                    })}


                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-between'>


                                                        <button className="btn btn-danger main-action-button main_qus_sample_usd " style={{ backgroundColor: "gray" }}>USD 50 Per Sample (Dynamic)</button>



                                                        <button className="btn btn-danger main-action-button main_qus_sample_upload ml-2" style={{ backgroundColor: "gray" }}>Upload Survey Link</button>

                                                    </div>


                                                    <div className="card-footer border-0 text-center p-0 mt-4  mb-3">

                                                        <button className="btn btn-primary main-action-button main_qus_sample">Express your Interest</button>
                                                    </div>

                                                </div>
                                            </div>

                                        </form>
                                    </div>



                                </div> */}


                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Survey_sampling_retail