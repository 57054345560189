import React from 'react'
import '../../form.scss'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { SelectPicker } from 'rsuite';
import { checkExpiry } from '../../Common/ExpiryTimeCheck';
import { getMasterData } from '../../Api/MasterData';
import { format } from 'date-fns';
import Swal from "sweetalert2";
import { getTotalEffortData, saveMasterData, saveQuestionData } from '../../Api/SurveyScripting';
import { postAPI, uploadImageAPI } from '../../ApiService';

function Survey_scripting_page() {
    const token = localStorage.getItem('token');
    const noOfQue = [];
    for (let index = 0; index < 100; index++) {
        noOfQue.push(index+1)
    }
    const navigate = useNavigate();
    const [languages, setLanguages] = useState();
    const [questionTypes, setQuestionTypes] = useState();
    const [surveyScriptPlatform, setSurveyScriptPlatform] = useState();
    const [surveyScriptingID, setSurveyScriptingID] = useState();
    const [activeStep1, setActiveStep1] = useState(true);
    const [activeStep2, setActiveStep2] = useState(false);
    const [activeStep3, setActiveStep3] = useState(false)
    const [effortTimes, setEffortTimes] = useState();
    const [selectedFile, setSelectedFile] = useState()
    // error states
    const [totalNoOfQue, settotalNoOfQue] = useState();
    const [noOfQuestion, setNoOfQuestion] = useState();
    const [totalqueserror, settotalqueserror] = useState();
    const [languageerror, setlanguageerror] = useState();
    const [quotaerror, setquotaserror] = useState();
    const [redirecterror, setredirecterror] = useState();
    const [dashboarderror, setdashboarderror] = useState();
    const [dateerror, setdateerror] = useState();
    const [typeerror, settypeerror] = useState();
    const [questionerror, setquestionerror] = useState();
    const [fileerr, setfileerr] = useState();
    // fields
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [selectedSurveyScriptPlatform, setSelectedSurveyScriptPlatform] = useState();
    const [quota, setquota] = useState();
    const [redirect, setredirect] = useState();
    const [dashboardFacility, setdashboard] = useState();
    const [totalNoOfSurvey, setTotalNoOfSurvey] = useState();
    const [totalNoOfSurveyError, setTotalNoOfSurveyError] = useState()
    const [date, setdate] = useState();
    const [question, setquestion] = useState();
    const [inputList, setinputList] = useState([{ form: '', check_tra: '', check: '' }]);
    const [inputListqus, setinputListqus] = useState([{ qustype: '', qusno: '' }]);
    
    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('languages')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getLanguages();
            } else {
                data = JSON.parse(localStorage.getItem('languages'))
                let langs = [];
                if (data.status === 1) {
                    data.payload.forEach(lang => {
                        langs.push({ label: lang.name, value: lang.id })
                    });
                    setLanguages(langs);
                }
            }
        }
    }, [])
    const getLanguages = async () => {
        let data = await getMasterData({ moduleName: 'languages', pagePerRec: 250, page: 0 })
        let langs = [];
        if (data.status === 1) {
            data.payload.forEach(lang => {
                langs.push({ label: lang.name, value: lang.id })
            });
            setLanguages(langs);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('question-types')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getQuestionTypes();
            } else {
                data = JSON.parse(localStorage.getItem('question-types'))
                if (data.status === 1) {
                    setQuestionTypes(data.payload);
                }
            }
        }
    }, [])
    const getQuestionTypes = async () => {
        let data = await getMasterData({ moduleName: 'question-types', pagePerRec: 250, page: 0 })
        if (data.status === 1) {
            setQuestionTypes(data.payload);
        }
    }

    useEffect(() => {
        if (token) {
            let checkExpiryTime = checkExpiry('survey-platform')
            let data
            if (checkExpiryTime === 'Expired') {
                data = getSurveyScriptPlatforms();
            } else {
                data = JSON.parse(localStorage.getItem('survey-platform'))
                if (data.status === 1) {
                    setSurveyScriptPlatform(data.payload);
                }
            }
        }
    }, [])
    const getSurveyScriptPlatforms = async () => {
        let data = await getMasterData({ moduleName: 'survey-platform', pagePerRec: 250, page: 0 })
        if (data.status === 1) {
            setSurveyScriptPlatform(data.payload);
        }
    }

    const handleTotalQuestion = (e) => {
        settotalNoOfQue(e.target.value)
        let noOfQues = [];
        for (let index = 0; index < e.target.value; index++) {
            noOfQues.push(index+1)
        }
        setNoOfQuestion(noOfQues)
    }

    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setinputList(list);

    }
    const handleremove = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    }
    const handleremove_qus = index => {
        const list = [...inputListqus];
        list.splice(index, 1);
        setinputListqus(list);
    }

    const handleaddclick = () => {

        setinputList([...inputList, { form: '', check_tra: '', check: '' }]);
    }

    const handlequschange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputListqus];
        list[index][name] = value;
        setinputListqus(list);

    }
    const handleaddqus = () => {
        if (inputListqus) {
            setinputListqus([...inputListqus, { qustype: '', qusno: '' }]);
        }
    }
    const totalq = (e) => {
    }

    useEffect(() => {
        document.title = "Survey Scripting - Mindforce"
    },);

    const next = async () => {
        settotalqueserror();
        setlanguageerror();
        setquotaserror()
        setredirecterror()
        setdashboarderror()
        setdateerror()
        setTotalNoOfSurveyError()
        let error = false;
        if (!noOfQuestion) {
            settotalqueserror(" *Required")
            error = true
        }
        if (selectedLanguage.length === 0) {
            setlanguageerror(" *Required")
            error = true
        }
        
        if (!dashboardFacility) {
            setdashboarderror("*Required")
            error = true
        }
        if (!date) {
            setdateerror(" *Required")
            error = true
        }
        if(!totalNoOfSurvey){
            setTotalNoOfSurveyError(" *Required")
            error = true
        }
        if(parseInt(totalNoOfSurvey) > 100000){
            setTotalNoOfSurveyError(" *Invalid")
            error = true
        }
        console.log('error', error)
        if(error){
            return error
        }
        let langu = [];
        for (let i = 0; i < selectedLanguage.length; i++) {
            let translation = document.getElementById('translation'+i);
            let isTrans = translation.checked ? 1 : 0;
            // let implementation = document.getElementById('implementation'+i);
            // let isImple = implementation.checked ? 1 : 0
            let isImple = 0
            langu.push({ID:selectedLanguage[i], translation: isTrans, implementation:isImple})
        }
        let payloadData = {
            totalQuestions: noOfQuestion.length,
            languages: langu,
            dashboardFacility: parseInt(dashboardFacility),
            noOfQuota: 1,
            noOfRedirect: 1,
            noOfSurvey: totalNoOfSurvey,
            scheduleDate:  Math.round(new Date(date).getTime() / 1000)
        }

        let res = await saveMasterData({payloadData:payloadData})
        if(res.status === 1){
            setActiveStep1(false)
            Swal.fire({
                // title: 'loading...',
                showCancelButton:false,
                showCloseButton: false,
                showConfirmButton:false,
                showDenyButton:false,
                timer: 1500,
                html: '<div><img src="https://i.pinimg.com/originals/78/e8/26/78e826ca1b9351214dfdd5e47f7e2024.gif" style={{maxHeight:"400px"}} /><br /><div>Please Wait</div></div>'
            }).then(async (result) => {
                console.log('step1', activeStep1)
                setActiveStep2(true);
                setSurveyScriptingID(res.payload.surveyScriptingID)
            });
            
        }
    }

    const Submit = async () => {
        setquestionerror()
        let totalQNos = 0
        let questions = [];
        for (let i = 0; i < inputListqus.length; i++) {
            let qtype = document.getElementById('questionType'+i);
            let qNo = document.getElementById('noOfQuestion'+i);
            totalQNos += parseInt(qNo.value);
            if(!qtype.value){
                return setquestionerror("Invalid question type")
            }
                
            questions.push({typeID:parseInt(qtype.value), noOfQuestions:parseInt(qNo.value)})
        }
        if(isNaN(totalQNos)){
            return setquestionerror("Please check number of question")
        }
        if(totalQNos > noOfQuestion.length || totalQNos < noOfQuestion.length){
            return setquestionerror("Please check number of question")
        }
        let payloadData = {
            surveyPlatformID:parseInt(selectedSurveyScriptPlatform),
            questions:questions
        }
        let res = await saveQuestionData({payloadData:payloadData, surveyScriptingID:surveyScriptingID})
        if(res.status === 1){
            Swal.fire({
                // title: 'Calculating...',
                showCancelButton:false,
                showCloseButton: false,
                showConfirmButton:false,
                showDenyButton:false,
                timer: 1500,
                html: '<div><img src="https://i.pinimg.com/originals/78/e8/26/78e826ca1b9351214dfdd5e47f7e2024.gif" style={{maxHeight:"400px"}} /><div>Calculating...</div></div>'
            }).then(async (result) => {
                setActiveStep3(true);
                let effortRes = await getTotalEffortData({surveyScriptingID:surveyScriptingID});
                if(effortRes.status === 1){
                    setActiveStep3(true);
                    setEffortTimes(effortRes.payload)
                }
            });
        }
    }

    const browseFile = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.id = 'uploadFile'
        input.onchange = _ => {
            let files =   Array.from(input.files);
            console.log(files);
            checkFile(files)
        };
        input.click();
    }
    const checkFile = async (file) => {
        var fileElement = file[0];
        var fileExtension = "";
        if (fileElement.name.lastIndexOf(".") > 0) {
            fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        }
        if (fileExtension.toLowerCase() == "doc" || fileExtension.toLowerCase() == "docx" || fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "xls"  || fileExtension.toLowerCase() == "xlsx") {
            setfileerr("");
            let res = await uploadImageAPI('/upload/common/',file)
            console.log('res', res)
            if(res.status === 1){
                setSelectedFile(res.url)
                let quefie = await postAPI('/survey-scripting/upload-questionnaire',{questionnaireFile:res.url})
                console.log('quefie',quefie)
                if(quefie.status === 1){
                    Swal.fire({
                        // title: 'Calculating...',
                        html:'<p style="color:#031b4e">Your survey questionnaire has been uploaded successfully. Our consultant will touch base with the estimation soon.</p>',
                        showCancelButton:false,
                        showCloseButton: false,
                        showConfirmButton:true,
                        showDenyButton:false,
                        // timer: 1500,
                    })
                }
            }
            
        }
        else {
            setfileerr("You must select a doc/xls/pdf file for upload");
            return false;
        }
    }

    const handleSelectLanguage = (val) => {
        let selLang = selectedLanguage;
        if(selectedLanguage.indexOf(val) < 0){
            selLang.push(val);
        }
        setSelectedLanguage(selLang);
    }

    const handleNoOfRedirecct = (e) =>{
        setredirecterror()
        if(parseInt(e.target.value) < 101){
            console.log("1")
            setredirect(e.target.value)
        }else{
            console.log("2")
            setredirect()
            setredirecterror("Invalid")
        }
    }

    const resetAll = () => {
        window.location.reload(false)
    }

    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">
                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">
                            <div className="side-app">
                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item  "><a ><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/icons/Survey-Scripting.png'></img>Survey Scripting</a></li>
                                            {/* <li className="breadcrumb-item active" aria-current="page"><a href="/step1">Step 1</a></li> */}
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='mb-6'>
                                    <img src='/img/bannerclient/SURVEY-SCRIPTING.jpg' alt='' className='banner' />
                                </div>
                                <div className="row " >
                                    <div className="col-lg-4 col-md-12 col-sm-12" >
                                        < >
                                            <div className="card border-0">
                                                <div className='text-center mb-0 '>
                                                    <h6 className=" step_surveyscript steps_survey">Step 1</h6>
                                                </div>
                                                <div className="card-body p-5 pb-0 ptsurvey scripting">
                                               <center><h6 className='title_step mb-3'>Define your survey</h6></center>
                                                
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 ">
                                                            <div className='row'>
                                                                <div className="col-lg-12 col-sm-12">
                                                                    <div className="form-group">
                                                                        <h6 className="task-heading">Total no. of Questions  <span className='errorMessage'> {totalqueserror}</span></h6>
                                                                        <select id="totalNoOfQue" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => handleTotalQuestion(e)}>
                                                                            <option value={""} disabled selected>Select </option>
                                                                            {noOfQue?.map((queNo) => {
                                                                                return (<><option value={queNo}>{queNo}</option></>)
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-lg-12 ">
                                                            <hr className=''></hr>
                                                            <div className='text-center mb-0'>
                                                                <h6 className="task-heading lan_count">Languages: {inputList.length}</h6>
                                                            </div>
                                                            <div className='row'>
                                                                {
                                                                    inputList?.map((x, i) => {
                                                                        return (
                                                                            <>
                                                                                <div className='col-lg-8 '>
                                                                                    <div className="form-group ">
                                                                                        <h6 className="task-heading">Language  <span className='errorMessage'> {languageerror}</span></h6>
                                                                                        <SelectPicker data={languages} size="sm" block 
                                                                                            // onChange={(e) => setlanguage(e.target.value)} 
                                                                                            onChange={(e) => handleSelectLanguage(e)} 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-4'>
                                                                                    <div className='d-flex'>
                                                                                        <h6 className="task-heading ">Translation</h6>
                                                                                        {/* <h6 className="task-heading ml-2">Implementation</h6> */}
                                                                                    </div>
                                                                                    <div className="form-group d-flex toggle_lan ">
                                                                                        <label class="custom-switch">
                                                                                            <input id={"translation"+i} type="checkbox" class="custom-switch-input" />
                                                                                            <span class="custom-switch-indicator"></span>
                                                                                        </label>
                                                                                        {/* <label class="custom-switch">
                                                                                            <input id={"implementation"+i} type="checkbox" class="custom-switch-input" />
                                                                                            <span class="custom-switch-indicator"></span>
                                                                                        </label> */}
                                                                                        {inputList.length !== 1 &&
                                                                                            <i style={{ color: "red" }} className='fa fa-trash fs-16' onClick={() => handleremove(i)}></i>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <hr></hr>
                                                                                <div className='col-lg-12 mb-0'>
                                                                                    {inputList.length - 1 === i &&
                                                                                        <div className="text-center ">
                                                                                            <hr></hr>
                                                                                            <button className='btn btn-primary main-action-button add_lan' onClick={handleaddclick} >+ Add</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12 ">
                                                            <div className='row'>
                                                                <div className="col-sm-12 col-lg-12 ">
                                                                    <div className='row'>
                                                                        <div className="col-lg-12 col-sm-12">
                                                                            <div className="form-group">
                                                                                <h6 className="task-heading">Total no. of Survey  <span className='errorMessage'> {totalNoOfSurveyError}</span></h6>
                                                                                <input type="number" min={0} max={100000} className="form-control input_hight_control onlyNumber" onChange={(e) => {
                                                                                    setTotalNoOfSurveyError()
                                                                                    setTotalNoOfSurvey(parseInt(e.target.value))
                                                                                    if(parseInt(e.target.value) > 100000){
                                                                                        setTotalNoOfSurveyError("Invalid")
                                                                                    }
                                                                                }} placeholder="Please Enter" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className="form-group">
                                                                        <h6 className="task-heading">Dashboard Facility  <span className='errorMessage'> {dashboarderror}</span></h6>
                                                                        <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setdashboard(e.target.value)}>
                                                                            <option value={""} disabled selected>Select</option>
                                                                            <option value={1}>Yes</option>
                                                                            <option value={0}>No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className="form-group form_fill_formcontrol">
                                                                        <h6 className="task-heading">Schedule date <span className='errorMessage'> {dateerror}</span></h6>

                                                                        <input className='form-control' type="date" min={format(new Date(), 'yyyy-MM-d').toString()} onChange={(e) => setdate(e.target.value)}></input>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer border-0 text-center p-0 ">
                                                        <button  disabled={activeStep1 ? false:true} className={activeStep1 ? ("btn btn-primary main-action-button main_qus_s") : ("btn btn-light main-action-button main_qus_s")} onClick={() => next()} >Next {activeStep1}</button>
                                                        {!activeStep1 ? (<>
                                                            <button className={"btn btn-primary main-action-button main_qus_s"} style={{marginLeft:"10px"}} onClick={() => resetAll()}>Reset</button>
                                                        </>) : (<>
                                                            
                                                        </>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12" >
                                        < >
                                            <div className="card border-0" style={{pointerEvents:activeStep2 ? '' : 'none'}}>
                                            {/* <div className="card border-0" style={{pointerEvents:activeStep2 ? '' : ''}}> */}
                                            <div className='text-center mb-0 '>
                                                                <h6 className=" step_surveyscript steps_survey" style={{backgroundColor: !activeStep2 ? '#e9e9e9':'' }}>Step 2</h6>
                                                            </div>
                                                <div className="card-body p-5 pb-0 ptsurvey scripting">
                                                <center><h6 className='title_step mb-3'>Detail out your questionnaire</h6></center>
                                                    <hr className=' mb-0'></hr>
                                                    <div className='text-center'>
                                                        <h6 className="task-heading ques_count">Questions</h6>
                                                    </div>
                                                    <div className='row'>
                                                        {questionerror ? (<><div style={{color:'red', fontWeight:"900", fontSize:"12px", marginLeft:"10px"}}> {questionerror} </div></>) : (<></>)}
                                                        <div className='col-sm-12 col-lg-12'>
                                                            {
                                                                inputListqus.map((x, i) => {
                                                                    return (
                                                                        <>
                                                                            <div className='row'>

                                                                            <div className='col-sm-6 col-lg-6'>

<div className="form-group ">
    <h6 className="task-heading"> Question Type  <span className='errorMessage'> {typeerror}</span></h6>

    <select id={"questionType" + i} className="form-select" data-choices data-choices-sorting="true">
        <option value={""} disabled selected>Select </option>
        {questionTypes?.map((qtype) => {
            return (<>
                <option value={qtype.id}>{qtype.name}</option>
            </>)
        })}
    </select>
</div>
</div>
<div className='col-sm-6 col-lg-6 '>
<div className="form-group">
    <h6 className="task-heading">No. of Questions </h6>

    <select id={"noOfQuestion" + i} className="form-select" data-choices data-choices-sorting="true" onChange={(e) => { totalq(e.target.value); setquestion(e.target.value) }}>
        <option value={""} disabled selected>Select </option>
        {noOfQuestion?.map((noOfQue) => {
            return (<><option value={noOfQue}>{noOfQue}</option></>)
        })}
    </select>
</div>
</div>

<div className=''>
{inputListqus.length !== 1 &&
<i style={{ color: "red" }} className='fa fa-trash trash_ques fs-16' onClick={() => handleremove_qus(i)}></i>
}
</div>

</div>

                                                                            
                                                                            {inputListqus.length - 1 === i &&
                                                                                <div className="card-footer border-0 text-center p-0 add_qustions">
                                                                                    <hr></hr>
                                                                                    <button className='btn btn-primary main-action-button add_lan' onClick={handleaddqus} >+ Add</button>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    );
                                                                })}
                                                            
                                                        </div>
                                                        <div className='col-lg-12'>

                                                            <div className='mb-2'>
                                                                <h6 className="task-heading  "> Survey Scripting Platform:</h6>

                                                                <div className='row'>
                                                                    {surveyScriptPlatform?.map((ssp, i) => {
                                                                        return <div className='col-lg-6 mb-2' style={{ cursor: "pointer", fontSize: "12px" }} onClick={() => {
                                                                            setSelectedSurveyScriptPlatform(ssp.id)
                                                                        }}>
                                                                            {selectedSurveyScriptPlatform === ssp.id ?
                                                                                (<><i className="fa fa-check-circle" style={{ fontSize: "14px", color: "#07a11a" }}></i> &nbsp;</>)
                                                                                :
                                                                                (<><i className="fa fa-check-circle" style={{ fontSize: "14px" }}></i> &nbsp;</>)}
                                                                            {ssp.name}
                                                                        </div>
                                                                    })}

                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer border-0 text-center p-0 ">

                                                        <button disabled={activeStep2 ? false:true} className={activeStep2 ? ("btn btn-primary main-action-button main_qus_s h-7") : ("btn btn-light main-action-button main_qus_s h-7")} onClick={Submit} >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12" style={{pointerEvents:activeStep3 ? '' : 'none'}}>
                                        <form  >
                                            <div className="card border-0">
                                            <div className='text-center mb-0 '>
                                                <h6 className=" step_surveyscript steps_survey" style={{backgroundColor: !activeStep3 ? '#e9e9e9':'' }}>Result</h6>
                                            </div>
                                                <div className="card-body p-3 pb-0 ptsurvey table_scripting">
                                                        <center><h6 className='title_step mb-3'>Total Effort</h6></center>
                                                    
                                                    <hr className='hr'></hr>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-primary thh">
                                                                <th scope="col scope">Scope of Work</th>
                                                                <th scope="col">Estimated Effort Time</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="table-success">
                                                                <td>Questionnaire Review</td>
                                                                <td>
                                                                    {effortTimes ? (<>
                                                                        {effortTimes?.questionnaireReview?.min === 0 && effortTimes?.questionnaireReview?.max === 0 ? (<>
                                                                            0 {effortTimes?.questionnaireReview?.unit}
                                                                        </>):(<>
                                                                            {effortTimes?.questionnaireReview?.min + '-' + effortTimes?.questionnaireReview?.max + ' '+ effortTimes?.questionnaireReview?.unit}
                                                                        </>)}
                                                                        
                                                                    </>) : (<></>)}
                                                                    
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr className="table-success">
                                                                <td>Survey Programming</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.surveyProgramming?.min === 0 && effortTimes?.surveyProgramming?.max === 0 ? (<>
                                                                            0 {effortTimes?.surveyProgramming?.unit}
                                                                        </>):(<>
                                                                            {effortTimes?.surveyProgramming?.min + '-' + effortTimes?.surveyProgramming?.max + ' '+ effortTimes?.surveyProgramming?.unit}
                                                                        </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Language Implementation</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.languageImplementation?.min === 0 && effortTimes?.languageImplementation?.max === 0 ? (<>
                                                                        0 {effortTimes?.languageImplementation?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.languageImplementation?.min + '-' + effortTimes?.languageImplementation?.max + ' '+ effortTimes?.languageImplementation?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Self Testing</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.selfTesting?.min === 0 && effortTimes?.selfTesting?.max === 0 ? (<>
                                                                        0 {effortTimes?.selfTesting?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.selfTesting?.min + '-' + effortTimes?.selfTesting?.max + ' '+ effortTimes?.selfTesting?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Peer Testing</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                {effortTimes?.peerTesting?.min === 0 && effortTimes?.peerTesting?.max === 0 ? (<>
                                                                        0 {effortTimes?.peerTesting?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.peerTesting?.min + '-' + effortTimes?.peerTesting?.max + ' '+ effortTimes?.peerTesting?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Quota Control</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.quotaControl?.min === 0 && effortTimes?.quotaControl?.max === 0 ? (<>
                                                                        0 {effortTimes?.quotaControl?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.quotaControl?.min + '-' + effortTimes?.quotaControl?.max + ' '+ effortTimes?.quotaControl?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Redirect Implementation</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.redirectImplementation?.min === 0 && effortTimes?.redirectImplementation?.max === 0 ? (<>
                                                                        0 {effortTimes?.redirectImplementation?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.redirectImplementation?.min + '-' + effortTimes?.redirectImplementation?.max + ' '+ effortTimes?.redirectImplementation?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Dashboard - Live</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.dashboardLive?.min === 0 && effortTimes?.dashboardLive?.max === 0 ? (<>
                                                                        0 {effortTimes?.dashboardLive?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.dashboardLive?.min + '-' + effortTimes?.dashboardLive?.max + ' '+ effortTimes?.dashboardLive?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="table-success">
                                                                <td>Dashboard - Manual</td>
                                                                <td>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.dashboardManual?.min === 0 && effortTimes?.dashboardManual?.max === 0 ? (<>
                                                                        0 {effortTimes?.dashboardManual?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.dashboardManual?.min + '-' + effortTimes?.dashboardManual?.max + ' '+ effortTimes?.dashboardManual?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr className="text-bold">
                                                                <td style={{fontWeight:"700"}}>Estimated Total Hours </td>
                                                                <td style={{fontWeight:"700"}}>
                                                                {effortTimes ? (<>
                                                                    {effortTimes?.totalEstimatedEffort?.min === 0 && effortTimes?.totalEstimatedEffort?.max === 0 ? (<>
                                                                        0 {effortTimes?.totalEstimatedEffort?.unit}
                                                                    </>):(<>
                                                                        {effortTimes?.totalEstimatedEffort?.min + '-' + effortTimes?.totalEstimatedEffort?.max + ' '+ effortTimes?.totalEstimatedEffort?.unit}
                                                                    </>)}
                                                                </>) : (<></>)}
                                                                
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </form>
                                        {/* <div className="card-footer border-0 text-center surveyques-upload p-0">
                                            <div className="form-group">
                                                <span className='errorMessage-file'> {fileerr}</span>
                                                <input name="uploadFile" id="uploadFile" type="file" className="form-control input_hight_control" />

                                            </div>
                                            <button className="btn btn-primary main-action-button Upload_qus" onClick={checkFile}>Upload Questionnaire (DOC/XLS/PDF)</button>
                                        </div> */}
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12" >
                                        <div className="card border-0">
                                            <div className="card-body p-5 pb-0 scripting">
                                                <hr className='mt-0 mb-0'></hr>
                                                <div className='text-center'>
                                                    <h6 className="task-heading ques_count " style={{fontSize:"18px"}}>OR</h6>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 customFile">
                                                        
                                                        <center>
                                                        <div className="upload-btn-wrapper">
                                                            <span style={{marginBottom: "20px",display: "block",fontSize: "18px",textAlign: "center",color: "#031b4e"}}>You can simply upload the questionnaire below &amp; our consultant will touch base with the estimation soon.</span>
                                                            <span className='errorMessage-file'> {fileerr}</span>
                                                            <button className="btn btn-primary upload_btn_survey" onClick={browseFile}> <i className="fa fa-upload"></i> Upload Questionnaire (DOC/XLS/PDF) </button>
                                                            {/* <input type="file" name="uploadFile" id="uploadFile1" /> */}
                                                        </div>
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Survey_scripting_page