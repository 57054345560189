import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import {  useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
const data = [{ name: 'Jan', value: '500',fill: '#1f77b4', },
{ name: 'Feb', value: '200' ,fill: '#1f77b4',},
{ name: 'Mar', value: '600' ,fill: '#1f77b4',},
{ name: 'Apr', value: '400',fill: '#1f77b4', },
{ name: 'May', value: '300',fill: '#1f77b4', },
{ name: 'Jun', value: '100',fill: '#1f77b4', },
{ name: 'Jul', value: '400',fill: '#1f77b4', },
{ name: 'Aug', value: '600' ,fill: '#1f77b4',},
{ name: 'Sep', value: '400',fill: '#1f77b4', },
{ name: 'Oct', value: '300',fill: '#1f77b4', },
{ name: 'Nov', value: '100',fill: '#1f77b4', },
{ name: 'Dec', value: '400',fill: '#1f77b4', },



];

function Retail() {
    useEffect(() => {
        
        document.title = "Retail - Mindforce"
      


    },);

  

    
    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">



                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">
                                       
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a ><img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='img/PanelBook.png'></img>Panel Book</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a >Retail</a></li>
                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                <div className='gif_comingsoon'>

<img src='/img/coming-soon.png'/>

</div>
                                {/* <div className='mb-3'>
                                <img src='/img/USAGEBAHITS.jpg' alt='' className='banner'/>

                                </div>

                                <div className="row">
                                    <div className="col-lg-3 col-md-12 col-sm-12">
                                        <form id="textract-form" enctype="multipart/form-data">
                                            <input type="hidden" name="_token" />


                                            <div className="card border-0">
                                                <div className="card-body p-5 pb-0">

                                                    <div className="row">
                                                        
                                                    <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">State</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select State" data-callback="userEnableTable">
                                                                <option value={""} disabled selected>Select State</option>
                                                                    <option value="1" >Delhi</option>
                                                                    <option value="2">Haryana</option>
                                                                    <option value="3">Punjab</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Town Class</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Town Class" data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select Town Class</option>
                                                                    <option value="1" >Class A</option>
                                                                    <option value="2">Class B</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Town</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Town  " data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select Town</option>
                                                                    <option value="1" >Gurgaon</option>
                                                                    <option value="2" >Faridabad</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Shop Category</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Shop Category  " >
                                                                <option value={""} disabled selected>Select Shop Category</option>
                                                                    <option value="1" >Retail</option>
                                                                    <option value="2" >Wholeseller</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                   
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Shop Type</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Shop Type" >
                                                                <option value={""} disabled selected>Select Shop Type</option>
                                                                <option value="1" >Retail</option>
                                                                    <option value="2" >Wholeseller</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                      

                                                       
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">No. of Employee </h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Employee Size" data-callback="userEnableReceipt">
                                                                <option value={""} disabled selected>Select Employee Size</option>
                                                                    <option value="enable" >10k</option>
                                                                    <option value="disable">10.5k</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Monthly Sales</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select Monthly Sales" data-callback="userEnableTable">
                                                                <option value={""} disabled selected>Select Monthly Sales</option>
                                                                    <option value="enable" >Management</option>
                                                                    <option value="disable">All</option>
                                                                </select>
                                                            </div>
                                                        </div>
                    
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Customer Deal in a Day</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " >
                                                                <option value={""} disabled selected>Select Deal</option>
                                                                <option value="100" >100</option>
                                                                    <option value="200" >200</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Home Delivery Facility</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " >
                                                                <option value={""} disabled selected>Select Facility</option>
                                                                <option value="100" >Yes</option>
                                                                    <option value="200" >No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Payment UPI Used</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " >
                                                                <option value={""} disabled selected>Select UPI </option>
                                                                <option value="100" >Yes</option>
                                                                    <option value="200" >No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <h6 className="task-heading">Online Platform</h6>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" data-placeholder="Select " >
                                                                <option value={""} disabled selected>Select </option>
                                                                <option value="100" >Yes</option>
                                                                    <option value="200" >No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="card-footer border-0 text-center p-0 ">
                                                 
                                                        <button className="btn btn-primary main-action-button main_qus_s">Show Result</button>
                                                    </div>

                                                </div>
                                            </div>

                                        </form>
                                    </div>

                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                        <div className="card border-0">
                                            <div className="card-header">
                                                <h3 className="card-title">Display Sample Size</h3>
                                                <a id="refresh-button" href="#" data-toggle="tooltip" data-placement="top" title="Refresh Table"><i className="fa fa-refresh transcribe-action-buttons transcribe-result"></i></a>
                                            </div>
                                            <div className="card-body pt-2">
                                            <BarChart width={700} height={400} data={data}>
                                                    <XAxis dataKey="name" stroke="#8884d8" />
                                                    <YAxis />
                                                    <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#ccc' }} />
                                                    <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                    <Bar dataKey="value" fill="fill" barSize={30} />
                                                </BarChart>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}


                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default Retail