import React from 'react'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarCases from './Sidebar_cases';

function DrivingAdoptionOFAiAlignedWithBusinessGoals() {


    useEffect(() => {

        document.title = "Case Study Detail - Mindforce"


    },);




    return (
        <>
            <Sidebar />
            <Header />
            <div className="app sidebar-mini">
                <div className="page">
                    <div className="page-main">


                        {/* <!-- APP CONTENT -->			 */}
                        <div className="app-content main-content">

                            <div className="side-app">

                                {/* <!-- PAGE HEADER --> */}
                                <div className="page-header mt-5-7">
                                    <div className="page-leftheader">

                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item"><a >
                                                <img className="side-menu__icon breadcrumb_img mr-2 fs-12" src='/img/icons/Casestudy.png'></img>Case Study</a></li>

                                        </ol>
                                    </div>
                                </div>
                                {/* <!-- END PAGE HEADER --> */}
                                {/* banner */}
                                <div className='case_study_sec'>

                                    <div class="row">

                                        <div class="col-lg-8 work-grid-box">
                                            <div class="grid-box-in">
                                                <div class="cases-img">
                                                    <img src="/img/casestudy/cases-larg-1.jpg" alt="cases" />
                                                </div>
                                               <h1 className='h2 mt-4'>Driving adoption of AI aligned with business goals</h1>
                                            </div>
                                            <div class="cases-detail pt-100">
                                                <div class="cases-content">
                                                    <div class="cases-box wow fadeInLeft animated">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Objective</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The Client wanted to understand –</p>
                                                            <ul>
                                                                <li>The significant business impact of AI and AI enabled systems on the businesses and its quantifiable benefits.</li>
                                                                <li>The type of AI enabled business applications with embedded AI support used by the organizations.</li>
                                                                <li>The key barriers inhibiting the deployment of AI systems.</li>
                                                            </ul>                                        </div>
                                                    </div>
                                                    <div class="cases-box wow fadeInLeft animated">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Methodology</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>CATI</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box wow fadeInLeft animated">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Respondents</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>Manager and above (IT Decision makers)</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box wow fadeInLeft animated">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Sample</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>450 interviews</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box wow fadeInLeft animated">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Geography</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>North America, Europe, France, Germany, Italy, Spain, Netherlands</p>                                        </div>
                                                    </div>
                                                    <div class="cases-box wow fadeInLeft">
                                                        <div class="cases-left">
                                                            <span class="cases-title">Result</span>
                                                        </div>
                                                        <div class="cases-right">
                                                            <p>The research helped the client understand current and planned business goals driving the adoption of AI systems in organizations. We assisted our client by providing SPSS data files along with charts to jump start their analysis.</p>                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <SidebarCases />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <!-- END APP CONTENT --> */}

                    {/* <!-- FOOTER --> */}
                    <Footer />
                    {/* <!-- END FOOTER --> */}


                </div>

            </div>
        </>
    )
}

export default DrivingAdoptionOFAiAlignedWithBusinessGoals