import { json } from "react-router-dom";
import { postAPI } from "../ApiService";
import { expiryTime } from "../Common/ExpiryTimeCheck";

export const getCategoryProductList = async({moduleName, subModuleName, payloadData, time}) =>{
    let res = await postAPI('/products/list/', payloadData)
    res.expiryTime = expiryTime(time)
    let key = moduleName+(subModuleName ? subModuleName : '')
    localStorage.setItem(key, JSON.stringify(res));
    return res;
}